import React, { useState, useEffect } from 'react';

import { getDocument } from '../../DiscourseIndex/components/restApi';
import {
  setCursorPositionInDOM,
} from './RichTextEditerHelpers';

import DiscourseLoading from './DiscourseLoading';
import DiscoursePublishingInfoSection from './DiscoursePublishingInfoSection';
import DiscourseMainSection from './DiscourseMainSection';
import DiscourseDebugger from './DiscourseDebugger';
import Feedback from '../../Feedback/components/Feedback';

import DocumentManager from './DocumentManager';

import styles from './Discourse.module.scss';

const Discourse = ({ authenticatedUser, id, openWithContentEditable }) => {
  const [state, setState] = useState({
    documentManager: new DocumentManager(),
    loading: !!id,
    feedbackMessage: null,
    feedbackVariant: null,
  });

  const clearFeedback = () => {
    setState((prevState) => {
      const nextState = {
        ...prevState,
        feedbackMessage: null,
        feedbackVariant: null,
      };

      return nextState;
    });
  };

  const onResponse = (response) => {
    setState(prevState => {
      const nextState = { ...prevState, loading: response.loading };
      nextState.documentManager.addDocumentToHistoryFromData(response);

      return nextState;
    });
  };

  const onError = feedback => setState(prevState => ({ ...prevState, ...feedback }));

  useEffect(() => {
    if (id) {
      getDocument({
        documentId: id,
        onResponse,
        onError,
      });
    }
  }, []);

  useEffect(() => {
    setCursorPositionInDOM({
      editableContentId: styles.mainSection,
      isibuteDocument: state.documentManager.getCurrentDocument(),
    });
  }, [state.documentManager.historyIndex]);

  if (state.loading) {
    return <DiscourseLoading />;
  }

  return (
    <>
      <DiscoursePublishingInfoSection document={state.documentManager.getCurrentDocument()} />
      <DiscourseMainSection
        authenticatedUser={authenticatedUser}
        setState={setState}
        state={state}
        openWithContentEditable={openWithContentEditable}
      />
      <DiscourseDebugger state={state} />
      <Feedback
        message={state?.feedbackMessage}
        variant={state?.feedbackVariant}
        closeFeedback={clearFeedback}
      />
    </>
  );
};

export default Discourse;
