import React from 'react';

import {
  useAppState,
  useAppDispatch,
} from '../../Shared/StateContext';

import Feedback from '../../Feedback/components/Feedback';

const AppFeedback = () => {
  const appState = useAppState();

  const appDispatch = useAppDispatch();

  const clearFeedback = () => {
    appDispatch({ type: 'CLEAR_FEEDBACK' });
  };

  return (
    <Feedback
      message={appState?.feedbackMessage}
      variant={appState?.feedbackVariant}
      closeFeedback={clearFeedback}
    />
  );
};

export default AppFeedback;
