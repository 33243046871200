import PropTypes from 'prop-types';
import React from 'react';

import styles from './TeamBuilderRequestReceived.module.scss';

const TeamBuilderRequestReceived = ({ isActive, sharedAssetsPaths, closeTeamBuilderRequestWizard }) => {
  return isActive ? (
    <React.Fragment>
      <div id={styles.xComponentContainer}>
        <img src={sharedAssetsPaths.xComponent} id={styles.xComponent} onClick={closeTeamBuilderRequestWizard}></img>
      </div>
      <section id={styles.mainContent}>
        <div>
          <img id={styles.partyPopper} src={sharedAssetsPaths.partyPopper256}></img>
        </div>
        <div id={styles.messageContainer}>
          <p id={styles.message}>Thanks for your request. We’ll be in touch with you about that about building your team.</p>
        </div>
        <div>
          <p id={styles.homeCTA} onClick={closeTeamBuilderRequestWizard}>Go Back Home</p>
        </div>
      </section>
    </React.Fragment>
  ) : (
    <></>
  );
};

TeamBuilderRequestReceived.propTypes = {
  sharedAssetsPaths: PropTypes.shape({}).isRequired,
};

export default TeamBuilderRequestReceived;
