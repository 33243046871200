import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoginForm from './LoginForm';
import PasswordResetRequestForm from './PasswordResetRequestForm';
import Modal from 'react-modal';
import StepWizard from 'react-step-wizard';
import Feedback from '../../Feedback/components/Feedback';

import styles from './LoginWizard.module.scss';

const LoginWizard = ({wizardOpen, handleCloseWizard}) => {
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [feedbackVariant, setFeedbackVariant] = useState(null);

  const clearFeedback = () => {
    setFeedbackMessage(null);
    setFeedbackVariant(null);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={wizardOpen}
        className={styles.modal}
        overlayClassName={styles.overlay}
        shouldCloseOnOverlayClick={false}
        onRequestClose={handleCloseWizard}
      >
        <StepWizard transitions={{}}>
          <LoginForm
            handleCloseWizard={handleCloseWizard}
            setFeedbackMessage={setFeedbackMessage}
            setFeedbackVariant={setFeedbackVariant}
          />
          <PasswordResetRequestForm
            handleCloseWizard={handleCloseWizard}
            setFeedbackMessage={setFeedbackMessage}
            setFeedbackVariant={setFeedbackVariant}
          />
        </StepWizard>
      </Modal>
      <Feedback
        message={feedbackMessage}
        variant={feedbackVariant}
        closeFeedback={clearFeedback}
      />
    </React.Fragment>
  );
};

LoginWizard.propTypes = {
  wizardOpen: PropTypes.bool.isRequired,
  handleCloseWizard: PropTypes.func.isRequired,
};

export default LoginWizard;
