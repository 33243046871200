import React, { useState } from 'react';
import RichTextEditor from 'react-rte';

import { createPost, getUrlMetadata } from '../../Feed/components/restApi';

import styles from './PostForm.module.scss';

const PostForm = ({ handleCloseWizard }) => {
  const [message, setMessage] = useState(RichTextEditor.createEmptyValue());
  const [postAttachment, setPostAttachment] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const payloadBody = () => {
    return {
      authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      message: message.toString('html'),
      attachement_type: postAttachment?.type || null,
      attached_image: postAttachment?.storableImageString || null,
      attached_image_file_name: postAttachment?.imageFileName || null,
      attached_webpage_url: postAttachment?.url || null,
      attached_webpage_image_url: postAttachment?.image || null,
    };
  };

  const payload = () => {
    return {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payloadBody()),
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (
        message.getEditorState().getCurrentContent().getPlainText().length < 1 ||
        message.getEditorState().getCurrentContent().getPlainText().length > 1500 ||
        message.toString('html').length > 1500
      ) {
      return null;
    }

    createPost({
      onResponse: (data) => {
        if (data.feedbackVariant === 'success') {
          window.location.assign("/feed");
        } else {
          setErrorMessage(data.message);
        }
      },
      onError: ({ feedbackMessage, feedbackVariant }) => setErrorMessage(feedbackMessage),
      body: {
        authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content"),
        message: message.toString('html'),
        attachement_type: postAttachment?.type || null,
        attached_image: postAttachment?.storableImageString || null,
        attached_image_file_name: postAttachment?.imageFileName || null,
        attached_webpage_url: postAttachment?.url || null,
        attached_webpage_image_url: postAttachment?.image || null,
      },
    })
  };

  const base64Image = (image) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const readerResult = reader.result;

      setPostAttachment({
        type: 'uploaded-image',
        imageFileName: image.name,
        previewableImageString: readerResult,
        storableImageString: readerResult.replace("data", "").replace(/^.+,/, ""),
      });
    };

    reader.readAsDataURL(image);
  };

  const postIsWithinPlainTextCharacterLimit = () => {
    return message.getEditorState().getCurrentContent().getPlainText().length <= 1500;
  };

  const plainTextCharacterCountMessage = () => {
    if (postIsWithinPlainTextCharacterLimit()) {
      return `${message.getEditorState().getCurrentContent().getPlainText().length} plain text characters of 1500 character limit`;
    } else {
      return `This post has ${message.getEditorState().getCurrentContent().getPlainText().length} characters, which exceeds the 1500 charater limit. It will not successfully submit until you decrease the character count.`;
    }
  }

  const postIsWithinHyperTextCharacterLimit = () => {
    return message.toString('html').length <= 1500;
  };

  const hyperTextCharacterCountMessage = () => {
    if (postIsWithinHyperTextCharacterLimit()) {
      return `${message.toString('html').length} hypertext characters of 1500 character limit`;
    } else {
      return `This post has ${message.toString('html').length} hypertext characters, which exceeds the 1500 charater limit. Some of these hypertext characters are hidden from view and generated when you create special html elements such as links. The post will not successfully submit until you decrease the character count.`;
    }
  };

  const detectUrlAndRetrieveMetadata = (value) => {
    const urlRegexWithProtocol = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;
    const urlRegexWithoutProtocol = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;

    const html = value.toString('html');
    if (urlRegexWithProtocol.test(html) || urlRegexWithoutProtocol.test(html)) {
      const protocolMatch = html.match(urlRegexWithProtocol) || [];
      const noProtocolMatch = html.match(urlRegexWithoutProtocol) || [];
      const detectedUrl = protocolMatch.concat(noProtocolMatch)[0];

      getUrlMetadata({
        detectedUrl,
        onResponse: (data) => {
          if (data.feedbackVariant === 'success') {
            setPostAttachment({
              type: 'url-metadata',
              url: data.url,
              image: data.image,
            });
          }
        },
        onError: ({ feedbackMessage, feedbackVariant }) => setErrorMessage(feedbackMessage),
      });
    }
  };

  const processMessageChange = (value) => {
    setMessage(value);
    detectUrlAndRetrieveMetadata(value);
  }

  return (
    <React.Fragment>
      { errorMessage && <h3 className="error"> { errorMessage } </h3> }
      <form onSubmit={onSubmit} id={styles.loginForm}>
        <label className={styles.inputLabel}>Upload Image (Optional)</label>
        <input
          className={styles.input}
          type="file"
          accept="image/png,image/gif,image/jpeg"
          onChange={(e) => { base64Image(e.target.files[0]) }}
        />

        <hr/>

        {postAttachment && postAttachment.type == 'url-metadata' && (
          <div>
            <a href={postAttachment.url} target="_blank"><img class={styles.img} src={postAttachment.image} /></a>
          </div>
        )}

        {postAttachment && postAttachment.type == 'uploaded-image' && (
          <div>
            <img class={styles.img} src={postAttachment.previewableImageString} />
          </div>
        )}

        <label className={styles.inputLabel}>Message</label>
        <RichTextEditor
          className={styles.textarea}
          value={message}
          onChange={(value) => { processMessageChange(value) }}
          autoFocus
        />

        <p className={postIsWithinPlainTextCharacterLimit() ? styles.goodCharacterCount : styles.exceededCharacterCount}>{plainTextCharacterCountMessage()}</p>
        <p className={postIsWithinHyperTextCharacterLimit() ? styles.goodCharacterCount : styles.exceededCharacterCount}>{hyperTextCharacterCountMessage()}</p>

        <button id={styles.cancelPostBtn} className={styles.postBtn} onClick={handleCloseWizard}>Cancel</button>
        <input type="submit" name="commit" value="Create" id={styles.createPostBtn} className={styles.postBtn} data-disable-with="Create" />
      </form>
    </React.Fragment>
  );
};

export default PostForm;
