import IsibuteDocumentComponent from "./IsibuteDocumentComponent";
import { documentComponentFromData } from "./DocumentComponentRegistry";

class IsibuteDocumentCitationsSection extends IsibuteDocumentComponent {
  constructor({
    id,
    documentCitations,
    unstyledContent,
    styling,
    ordinal,
    documentComponentType,
    parentDocumentComponentId,
    firstChildDocumentComponentId,
    lastChildDocumentComponentId,
    previousDocumentComponentId,
    nextDocumentComponentId,
    isibuteDocument,
  }) {
    super({
      id,
      unstyledContent,
      styling,
      ordinal,
      documentComponentType,
      parentDocumentComponentId,
      firstChildDocumentComponentId,
      lastChildDocumentComponentId,
      previousDocumentComponentId,
      nextDocumentComponentId,
      isibuteDocument,
    });

    this.documentCitations = {};

    if (documentCitations) {
      Object.entries(documentCitations).forEach(([key, value]) => {
        this.documentCitations[key] = documentComponentFromData({ ...value, isibuteDocument });
      });
    }
  }

  asJSON = () => {
    return {
      id: this.id,
      unstyledContent: this.unstyledContent,
      styling: this.styling,
      ordinal: this.ordinal,
      documentComponentType: this.documentComponentType,
      parentDocumentComponentId: this.parentDocumentComponentId,
      firstChildDocumentComponentId: this.firstChildDocumentComponentId,
      lastChildDocumentComponentId: this.lastChildDocumentComponentId,
      previousDocumentComponentId: this.previousDocumentComponentId,
      nextDocumentComponentId: this.nextDocumentComponentId,
      documentCitations: Object.values(this.documentCitations).map((documentComponent) => {
        return documentComponent.asJSON();
      }),
    }
  }

  deepCopy = () => {
    const copy = new this.constructor({
      id: this.id,
      unstyledContent: this.unstyledContent,
      styling: this.styling,
      ordinal: this.ordinal,
      documentComponentType: this.documentComponentType,
      parentDocumentComponentId: this.parentDocumentComponentId,
      firstChildDocumentComponentId: this.firstChildDocumentComponentId,
      lastChildDocumentComponentId: this.lastChildDocumentComponentId,
      previousDocumentComponentId: this.previousDocumentComponentId,
      nextDocumentComponentId: this.nextDocumentComponentId,
      isibuteDocument: this.isibuteDocument,
    });

    Object.entries(this.documentCitations).forEach(([key, value]) => {
      copy.documentCitations[key] = value.deepCopy();
    });

    return copy;
  };

  getDocumentComponentByKey = (key) => {
    return this.documentCitations[key] || null;
  };

  getDocumentComponentByKeyPath = ({ keyPath }) => {
    const currentDocumentComponent = this.getDocumentComponentByKey(keyPath.shift());

    if (keyPath.length > 0) {
      return currentDocumentComponent?.getDocumentComponentByKeyPath({ keyPath }) || null;
    }

    return currentDocumentComponent;
  };
};

export default IsibuteDocumentCitationsSection;