import React from 'react';

import styles from './Discourse.module.scss';

import { getCursorPositionInDOM } from './RichTextEditerHelpers';

const DiscourseDebugger = ({ state }) => {
  const stateDisplay = () => {
    return Object.entries(state).map(([key, value]) => {
      return (<div>{`${key}: ${value}`}</div>);
    });
  };
  
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const showDebugHelper = urlParams.get('show_debug_helper');
  const isibuteDocument = state?.documentManager?.getCurrentDocument();

  if (showDebugHelper) {
    return (
      <>
        <div class={styles.stateDisplay}>
          <p>State Cursor Position: {isibuteDocument.cursorPosition}</p>
          <p>DOM Cursor Position: {getCursorPositionInDOM({ editableContentId: styles.mainSection })}</p>
          <p>Current Node Cursor Position: {isibuteDocument?.currentNodeCursorPosition}</p>
          <p>Current Node Parent Id: {isibuteDocument?.currentNode?.parentDocumentComponentId}</p>
          <p>Current Node Id: {isibuteDocument?.currentNode?.id}</p>
        </div>
        <div class={styles.stateDisplay}>
          {stateDisplay()}
        </div>
      </>
    );
  }

  return null;
};

export default DiscourseDebugger;