import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { updateCurrentAccountPrimaryEmail } from './restApi';

import styles from './EmailUpdateForm.module.scss';

const EmailUpdateForm = ({ handleEmailUpdate, handleCloseEmailUpdateModal }) => {
  const [primaryEmail, setPrimaryEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleEmailTextChange = (e) => {
    setPrimaryEmail(e.target.value);
  };

  const handlePasswordTextChange = (e) => {
    setPassword(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    updateCurrentAccountPrimaryEmail({
      onResponse: (data) => {
        if (data.feedbackVariant === 'success') {
          handleEmailUpdate(primaryEmail);
          handleCloseEmailUpdateModal();
        } else {
          setErrorMessage(data.error_message);
        }
      },
      onError: ({ feedbackMessage, feedbackVariant }) => setErrorMessage(feedbackMessage),
      body: {
        primary_email: primaryEmail,
        password: password,
        authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content")
      },
    });
  };

  return (
    <React.Fragment>
      { errorMessage && <h3 className="error"> { errorMessage } </h3> }

      <form onSubmit={onSubmit} id={styles.loginForm}>
        <label className={styles.inputLabel} htmlFor="email">Email</label>
        <input
          className={styles.input}
          type="email" name="session[email]"
          id="email"
          onChange={handleEmailTextChange}
        />

        <label className={styles.inputLabel} htmlFor="password">Password</label>
        <input
          className={styles.input}
          type="password"
          name="session[password]"
          id="password"
          onChange={handlePasswordTextChange}
        />

        <input type="submit" name="commit" value="Update Email" className={styles.loginBtn} data-disable-with="Update Email" />
      </form>
    </React.Fragment>
  );
};

EmailUpdateForm.propTypes = {
  handleCloseEmailUpdateModal: PropTypes.func.isRequired,
  handleEmailUpdate: PropTypes.func.isRequired,
};

export default EmailUpdateForm;
