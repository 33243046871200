import React, { useState, useEffect } from 'react';

import { updatePostViewState } from '../../Feed/components/restApi';

import styles from './Post.module.scss';
import ReactHtmlParser from 'react-html-parser';

const Post = ({
  post,
  handleOpenCommentModal,
  handleOpenCommentFormModal,
  setFeedbackMessage,
  setFeedbackVariant,
  moveViewState,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [subTextContainerIsLongerThanTextContainer, setSubTextContainerIsLongerThanTextContainer] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const goToAuthorProfile = () => {
    window.location.assign(post.author.path);
  }

  const mustBeExpandedToViewAllPostText = () => {
    return !expanded && subTextContainerIsLongerThanTextContainer;
  }

  const updatePostViewStateWrapper = (postId, state_event) => {
    updatePostViewState({
      postId,
      onResponse: (data) => {
        if (data.feedbackVariant === 'success') {
          moveViewState(data.post, data.post.viewState);
          setFeedbackMessage(data.message);
          setFeedbackVariant('success');
        } else {
          setErrorMessage(data.error_message);
        }
      },
      onError: ({ feedbackMessage, feedbackVariant }) => setErrorMessage(feedbackMessage),
      body: {
        state_event,
        authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content")
      },
    })
  };

  const parse = (text) => {
    return ReactHtmlParser(text, {
      transform: (node) => {
        if (node.type === 'tag' && node.name === 'a') {
          const finalHref = node?.attribs?.href?.includes('http') ? node?.attribs?.href : `//${node?.attribs?.href}`;
          return <a href={finalHref} target="_blank">{node.children[0].data}</a>;
        }
      }
    });
  };

  const finalLink = post.link?.includes('http') ? post.link : `//${post.link}`;

  const subTextContainerStyle = expanded ? styles.subTextContainer : styles.abbreviatedSubTextContainer;

  const showCommentOption = post.viewState == 'approved';

  const showSubmitOption = (post.viewState == 'draft') && post.author.canAdministratePosts;

  const showReviewOptions = (post.viewState == 'pending_approval') && post.author.canAdministratePosts;

  useEffect(() => {
    setSubTextContainerIsLongerThanTextContainer(document.getElementById(`subSubTextContainer_${post.id}`)?.offsetHeight > document.getElementById(`subTextContainer_${post.id}`)?.offsetHeight)
  }, [])

  return (
    <div class={styles.post}>
      {!!post.link ? (
        <a href={finalLink} target="_blank"><img class={styles.img} src={post.imagePath} /></a>
      ) : (
        <img class={styles.img} src={post.imagePath} />
      )}
      <div className={styles.authorIntro}>
        <img className={styles.authorPicture} src={post.author.profilePicturePath} onClick={goToAuthorProfile}></img>
        <p class={styles.authorName}  onClick={goToAuthorProfile}>{post.author.name}</p>
        <p className={styles.authorTagline}  onClick={goToAuthorProfile}>{post.author.primaryUserTag}</p>
        <p className={styles.postDate}>{`Posted On ${new Date(Date.parse(post.createdAt)).toLocaleDateString()}`}</p>
      </div>
      <div class={styles.textContainer}>
        <div id={`subTextContainer_${post.id}`} class={subTextContainerStyle}>
          <div id={`subSubTextContainer_${post.id}`} class={styles.subSubTextContainer}>
            {parse(post.text)}
          </div>
        </div>
        {mustBeExpandedToViewAllPostText() && (
          <p className={styles.showMore} onClick={() => setExpanded(true)}>...Show More</p>
        )}
      </div>
      <hr/>
      <div class={styles.commentsCountContainer}>
        <p class={styles.commentsCount} onClick={post.comments.length ? () => handleOpenCommentModal({commentParentId: post.id, commentParentType: 'Post'}) : () => {}}>{`${post.comments.length} ${post.comments.length == 1 ? 'Comment' : 'Comments'}`}</p>
      </div>
      <hr/>
      <div class={styles.interactionsContainer}>
        {showCommentOption && <p class={styles.interaction} onClick={() => handleOpenCommentFormModal({commentParentId: post.id, commentParentType: 'Post'})}>Make Comment</p>}
        {showSubmitOption && <p class={styles.interaction} onClick={() => updatePostViewStateWrapper(post.id, 'request_approval')}>Submit For Review</p>}
        {showReviewOptions && <p class={styles.interaction} onClick={() => updatePostViewStateWrapper(post.id, 'approve')}>Approve</p>}
        {showReviewOptions && <p class={styles.interaction} onClick={() => updatePostViewStateWrapper(post.id, 'deny')}>Deny</p>}
      </div>
    </div>
  );
};

export default Post;
