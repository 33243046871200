import { createContext, useContext, useReducer } from 'react';

const AppStateContext = createContext(null);

const AppDispatchContext = createContext(null);

export function AppStateProvider({
  children,
  initialState = {
    feedbackMessage: null,
    feedbackVariant: null,
  },
  reducer
}) {
  const [appState, appDispatch] = useReducer(
    reducer,
    initialState
  );

  return (
    <AppStateContext.Provider value={appState}>
      <AppDispatchContext.Provider value={appDispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};

export function useAppState() {
  return useContext(AppStateContext);
};

export function useAppDispatch() {
  return useContext(AppDispatchContext);
};
