import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { updateCurrentUserBiography } from './restApi';

import styles from './UserIntroductionUpdateForm.module.scss';

const UserIntroductionUpdateForm = ({ initialIntroduction, handleUserIntroductionUpdate, handleCloseUserIntroductionUpdateModal }) => {
  const [introduction, setIntroduction] = useState(initialIntroduction);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleIntroductionTextChange = (e) => {
    setIntroduction(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    updateCurrentUserBiography({
      onResponse: (data) => {
        if (data.feedbackVariant === 'success') {
          handleUserIntroductionUpdate(introduction);
          handleCloseUserIntroductionUpdateModal();
        } else {
          setErrorMessage(data.error_message);
        }
      },
      onError: ({ feedbackMessage, feedbackVariant }) => { setErrorMessage(feedbackMessage) },
      body: {
        biography: introduction,
        authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content")
      },
    });
  };

  return (
    <React.Fragment>
      { errorMessage && <h3 className="error"> { errorMessage } </h3> }

      <form onSubmit={onSubmit} id={styles.loginForm}>
        <label className={styles.inputLabel}>Introduction</label>
        <textarea
          class={styles.textarea}
          id="introduction"
          onChange={handleIntroductionTextChange}
          value={introduction}
        ></textarea>

        <input
          type="submit"
          name="commit"
          value="Update Introduction"
          className={styles.loginBtn}
          data-disable-with="Update Introduction"
        />
      </form>
    </React.Fragment>
  );
};

UserIntroductionUpdateForm.propTypes = {
  handleCloseUserIntroductionUpdateModal: PropTypes.func.isRequired,
  handleUserIntroductionUpdate: PropTypes.func.isRequired,
  initialIntroduction: PropTypes.string,
};

export default UserIntroductionUpdateForm;
