export function appReducer(appState, action) {
  switch (action.type) {
    case 'ON_RESPONSE': {
      return { ...appState, ...action.response };
    }
    case 'ON_ERROR': {
      return { ...appState, ...action.feedback };
    }
    case 'CLEAR_FEEDBACK': {
      const clearedFeedback = {
        feedbackMessage: null,
        feedbackVariant: null
      };

      return { ...appState, ...clearedFeedback };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};
