import React, { useEffect } from 'react';

import {
  useUserInvitationsState,
  useUserInvitationsDispatch
} from './UserInvitationsContext';

import {
  getOpenUserInvitations,
  closeInvitation,
} from './restApi';

import styles from './UserInvitations.module.scss';

const OpenUserInvitationsTable = () => {
  const userInvitationsState = useUserInvitationsState();

  const userInvitationsDispatch = useUserInvitationsDispatch();

  const authenticityToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

  const onResponse = response => userInvitationsDispatch({ type: 'ON_RESPONSE', response });

  const onError = feedback => userInvitationsDispatch({ type: 'ON_ERROR', feedback });

  useEffect(() => {
    getOpenUserInvitations({ onResponse, onError });
  }, []);

  return userInvitationsState?.openUserInvitations?.length ? (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Expires At</th>
        </tr>
      </thead>
      <tbody>
        {userInvitationsState?.openUserInvitations?.map(invitation => {
          return (
            <tr key={invitation.id}>
              <td>{invitation.firstName} {invitation.lastName}</td>
              <td>{invitation.email}</td>
              <td>{invitation.expiresAt}</td>
              <td>
                <button
                  onClick={() => {
                    closeInvitation({
                      invitation,
                      onResponse,
                      onError,
                      body: {
                        authenticity_token: authenticityToken,
                      },
                    });
                  }}
                  className={styles.closeInvitationBtn}
                >
                  Close Invitation
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : (
    <p className={styles.noContentMessage}>No open user invitations.</p>
  );
};

export default OpenUserInvitationsTable;
