import IsibuteDocumentComponent from "./IsibuteDocumentComponent";

class IsibuteDocumentImage extends IsibuteDocumentComponent {
  constructor({
    id,
    caption,
    altText,
    imagePath,
    unstyledContent,
    styling,
    ordinal,
    documentComponentType,
    displaySizeVariant,
    parentDocumentComponentId,
    firstChildDocumentComponentId,
    lastChildDocumentComponentId,
    previousDocumentComponentId,
    nextDocumentComponentId,
    isibuteDocument,
  }) {
    super({
      id,
      unstyledContent,
      styling,
      ordinal,
      documentComponentType,
      parentDocumentComponentId,
      firstChildDocumentComponentId,
      lastChildDocumentComponentId,
      previousDocumentComponentId,
      nextDocumentComponentId,
      isibuteDocument,
    });

    this.caption = caption;
    this.altText = altText;
    this.imagePath = imagePath;
    this.displaySizeVariant = displaySizeVariant;
  }

  asJSON = () => {
    return {
      id: this.id,
      caption: this.caption,
      altText: this.altText,
      imagePath: this.imagePath,
      unstyledContent: this.unstyledContent,
      styling: this.styling,
      ordinal: this.ordinal,
      documentComponentType: this.documentComponentType,
      displaySizeVariant: this.displaySizeVariant,
      parentDocumentComponentId: this.parentDocumentComponentId,
      firstChildDocumentComponentId: this.firstChildDocumentComponentId,
      lastChildDocumentComponentId: this.lastChildDocumentComponentId,
      previousDocumentComponentId: this.previousDocumentComponentId,
      nextDocumentComponentId: this.nextDocumentComponentId,
    };
  };

  deepCopy = () => {
    return new this.constructor({
      id: this.id,
      caption: this.caption,
      altText: this.altText,
      imagePath: this.imagePath,
      unstyledContent: this.unstyledContent,
      styling: this.styling,
      ordinal: this.ordinal,
      documentComponentType: this.documentComponentType,
      displaySizeVariant: this.displaySizeVariant,
      parentDocumentComponentId: this.parentDocumentComponentId,
      firstChildDocumentComponentId: this.firstChildDocumentComponentId,
      lastChildDocumentComponentId: this.lastChildDocumentComponentId,
      previousDocumentComponentId: this.previousDocumentComponentId,
      nextDocumentComponentId: this.nextDocumentComponentId,
      isibuteDocument: this.isibuteDocument,
    });
  };
};

export default IsibuteDocumentImage;
