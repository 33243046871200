import React, { useEffect, useState } from "react";

import { getRestrictedActions } from './restApi';

import styles from './Index.module.scss';

const Index = () => {
  const [state, setState] = useState({
    restrictedActions: [],
    feedbackMessage: null,
    feedbackVariant: null,
  });

  const onResponse = response => setState(prevState => ({ ...prevState, ...response }));

  const onError = feedback => setState(prevState => ({ ...prevState, ...feedback }));

  const goToCreateRestrictedAction = () => {
    location.assign('/restricted_actions/new');
  };

  const goToRestrictedAction = (restrictedAction) => {
    location.assign(`/restricted_actions/${restrictedAction.id}`);
  };

  useEffect(() => {
    getRestrictedActions({ onResponse, onError });
  }, []);

  return (
    <>
      <section>
        <button
          className={styles.createRestrictedActionsBtn}
          onClick={() => goToCreateRestrictedAction()}
        >
          Create Restricted Action
        </button>
      </section>
      <section>
        <h2 className={styles.tableTitle}>
          Restricted Actions
        </h2>
        {state?.restrictedActions?.length ? (
          <table>
            <thead>
              <tr>
                <th>Group Id</th>
                <th>Restricted Action Name</th>
                <th>Resource Id</th>
                <th>Resource Type</th>
                <th>Apply To All Users</th>
              </tr>
            </thead>
            <tbody>
              {state?.restrictedActions?.map(restrictedAction => {
                return (
                  <tr key={restrictedAction.id}>
                    <td>{restrictedAction.groupId}</td>
                    <td>{restrictedAction.restrictedActionName}</td>
                    <td>{restrictedAction.resourceId}</td>
                    <td>{restrictedAction.resourceType}</td>
                    <td>{restrictedAction.applyToAllUsers.toString()}</td>
                    <td>
                      <button
                        className={styles.viewDetailsBtn}
                        onClick={() => goToRestrictedAction(restrictedAction)}
                      >
                        View Details
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className={styles.noContentMessage}>No restricted actions.</p>
        )}
      </section>
    </>
  );
};

export default Index;
