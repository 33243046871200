/*
 * See https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods for information
 * on when and how to use each http request method
 */

export const HTTPRequestMethods = {
  GET: 'GET',
  HEAD: 'HEAD',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  CONNECT: 'CONNECT',
  OPTIONS: 'OPTIONS',
  TRACE: 'TRACE',
  PATCH: 'PATCH',
};
