import React from 'react';
import PropTypes from 'prop-types';
import CommentForm from './CommentForm';
import Modal from 'react-modal';

import styles from './CommentFormModal.module.scss';

const CommentFormModal = ({ modalOpen, handleCloseModal, parentId, parentType }) => {
  return (
    <Modal
      isOpen={modalOpen}
      className={styles.modal}
      overlayClassName={styles.overlay}
      shouldCloseOnOverlayClick={false}
      onRequestClose={handleCloseModal}
    >
      <CommentForm
        handleCloseModal={handleCloseModal}
        parentId={parentId}
        parentType={parentType}
      />
    </Modal>
  );
};

CommentFormModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default CommentFormModal;
