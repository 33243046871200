import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { createSession } from '../../Account/components/restApi';

import styles from './LoginForm.module.scss';


const LoginForm = ({ handleCloseWizard, nextStep, setFeedbackMessage, setFeedbackVariant }) => {
  const payloadBody = () => {
    const data = {
      email: document.getElementById('email').value,
      password: document.getElementById('password').value,
      authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content")
    }

    return data;
  }

  const payload = () => {
    return {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payloadBody()),
    }
  }

  const onSubmit = () => {
    createSession({
      onResponse: (data) => {
        if (data.feedbackVariant === 'success') {
          localStorage.setItem("IsibuteFeedbackMessage", "Successful login.");
          localStorage.setItem("IsibuteFeedbackVariant", "success");
          window.location.assign("/feed");
        } else {
          setFeedbackMessage(data.error_message || data.message);
          setFeedbackVariant("error");
        }
      },
      onError: ({ feedbackMessage, feedbackVariant }) => {
        setFeedbackMessage(feedbackMessage);
        setFeedbackVariant(feedbackVariant);
      },
      body: {
        email: document.getElementById('email').value,
        password: document.getElementById('password').value,
        authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content")
      },
    });
  }

  return (
    <React.Fragment>
      <div id={styles.closeModalContainer}>
        <a id={styles.closeModal} onClick={handleCloseWizard}>&#10005;</a>
      </div>
      <div id={styles.loginForm}>
        <label className={styles.inputLabel} htmlFor="email">Email</label>
        <input className={styles.input} type="email" name="session[email]" id="email" />

        <label className={styles.inputLabel} htmlFor="password">Password</label>
        <input className={styles.input} type="password" name="session[password]" id="password" />

        <p id={styles.forgotPassword} onClick={nextStep}>Forgot Your Password?</p>

        <button className={styles.loginBtn} onClick={onSubmit}>Log In</button>
      </div>
    </React.Fragment>
  );
};

LoginForm.defaultProps = {
  nextStep: () => {},
};

LoginForm.propTypes = {
  handleCloseWizard: PropTypes.func.isRequired,
  nextStep: PropTypes.func,
};

export default LoginForm;
