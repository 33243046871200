import React from 'react';

import {
  useUserInvitationsState,
  useUserInvitationsDispatch
} from './UserInvitationsContext';

import {
  generateAndSendOpenUserInvitation,
} from './restApi';

import styles from './UserInvitations.module.scss';

const NewOpenUserInvitationsForm = () => {
  const userInvitationsState = useUserInvitationsState();

  const userInvitationsDispatch = useUserInvitationsDispatch();

  const authenticityToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

  const onResponse = response => userInvitationsDispatch({ type: 'ON_RESPONSE', response });

  const onError = feedback => userInvitationsDispatch({ type: 'ON_ERROR', feedback });

  return (
    <form id={styles.invitationGenerationForm}>
      <input
        className={styles.input}
        type="text"
        id="firstName"
        placeholder="First Name:"
        onChange={
          (e) => {
            const firstName = e.target.value;
            userInvitationsDispatch({ type: 'UPDATE_FIRST_NAME', firstName });
          }
        }
        value={userInvitationsState?.newOpenUserInvitation?.firstName}
      />

      <input
        className={styles.input}
        type="text"
        id="lastName"
        placeholder="Last Name:"
        onChange={
          (e) => {
            const lastName = e.target.value;
            userInvitationsDispatch({ type: 'UPDATE_LAST_NAME', lastName });
          }
        }
        value={userInvitationsState?.newOpenUserInvitation?.lastName}
      />

      <input
        className={styles.input}
        type="email"
        id="email"
        placeholder="Email:"
        onChange={
          (e) => {
            const email = e.target.value;
            userInvitationsDispatch({ type: 'UPDATE_EMAIL', email });
          }
        }
        value={userInvitationsState?.newOpenUserInvitation?.email}
      />

      <button
        type="button"
        className={styles.generateAndSendInvitationBtn}
        onClick={() => {
          generateAndSendOpenUserInvitation({
            onResponse,
            onError,
            body: {
              newOpenUserInvitation: userInvitationsState?.newOpenUserInvitation,
              authenticity_token: authenticityToken,
            },
          });
        }}
      >
        Generate and send invitation
      </button>
    </form>
  );
};

export default NewOpenUserInvitationsForm;
