import React from 'react';

import styles from './Discourse.module.scss';

const DiscoursePublishingInfoSection = ({ document }) => {
  const localizedPublishedAt = new Date(Date.parse(document?.publishedAt)).toLocaleDateString();
  const authorProfilePath = document?.author?.path;
  const authorName = document?.author?.name;

  const shouldDisplayPublishingInfo = localizedPublishedAt && authorProfilePath && authorName;

  if (shouldDisplayPublishingInfo) {
    return (
      <div class={styles.publishingInfoSection}>
        <p class={styles.publishingInfo}>
          Published by <a id={styles.author} href={authorProfilePath}>{authorName}</a> on {localizedPublishedAt}
        </p>
      </div>
    );
  }

  return null;
};

export default DiscoursePublishingInfoSection;
