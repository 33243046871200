import React from "react";

import { RoleFormProvider } from "./RoleFormContext";

import RoleFormFeedback from "./RoleFormFeedback";
import RoleForm from "./RoleForm";

import {
  updateRole,
} from './restApi';

import styles from './RoleForm.module.scss';

const Update = ({ role }) => {
  const goToRole = (role) => {
    location.assign(`/roles/${role.id}`);
  };

  return (
    <RoleFormProvider>
      <RoleFormFeedback />
      <section>
        <h2 className={styles.sectionTitle}>
          Update Role
        </h2>
        <RoleForm role={role} onSubmit={updateRole}/>
        <button
          className={styles.cancelBtn}
          onClick={() => goToRole(role)}
        >
          Cancel
        </button>
      </section>
    </RoleFormProvider>
  );
}

export default Update;
