import React, { useState } from 'react';
import RichTextEditor from 'react-rte';

import { createComment } from '../../Feed/components/restApi';

import styles from './CommentForm.module.scss';

const CommentForm = ({ parentId, parentType, handleCloseModal }) => {
  const [message, setMessage] = useState(RichTextEditor.createEmptyValue());
  const [errorMessage, setErrorMessage] = useState(null);

  const payloadBody = () => {
    return {
      authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      message: message.toString('html'),
      parent_id: parentId,
      parent_type: parentType,
    };
  };

  const payload = () => {
    return {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payloadBody()),
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (
        message.getEditorState().getCurrentContent().getPlainText().length < 1 ||
        message.getEditorState().getCurrentContent().getPlainText().length > 1500 ||
        message.toString('html').length > 1500
      ) {
      return null;
    }

    createComment({
      onResponse: (data) => {
        if (data.feedbackVariant === 'success') {
          window.location.assign("/feed");
        } else {
          setErrorMessage(data.message);
        }
      },
      onError: ({ feedbackMessage, feedbackVariant }) => setErrorMessage(feedbackMessage),
      body: {
        authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content"),
        message: message.toString('html'),
        parent_id: parentId,
        parent_type: parentType,
      },
    });
  };

  const postIsWithinPlainTextCharacterLimit = () => {
    return message.getEditorState().getCurrentContent().getPlainText().length <= 1500;
  };

  const plainTextCharacterCountMessage = () => {
    if (postIsWithinPlainTextCharacterLimit()) {
      return `${message.getEditorState().getCurrentContent().getPlainText().length} plain text characters of 1500 character limit`;
    } else {
      return `This post has ${message.getEditorState().getCurrentContent().getPlainText().length} characters, which exceeds the 1500 charater limit. It will not successfully submit until you decrease the character count.`;
    }
  };

  const postIsWithinHyperTextCharacterLimit = () => {
    return message.toString('html').length <= 1500;
  };

  const hyperTextCharacterCountMessage = () => {
    if (postIsWithinHyperTextCharacterLimit()) {
      return `${message.toString('html').length} hypertext characters of 1500 character limit`;
    } else {
      return `This post has ${message.toString('html').length} hypertext characters, which exceeds the 1500 charater limit. Some of these hypertext characters are hidden from view and generated when you create special html elements such as links. The comment will not successfully submit until you decrease the character count.`;
    }
  };

  return (
    <React.Fragment>
      { errorMessage && <h3 className="error"> { errorMessage } </h3> }
      <form onSubmit={onSubmit} id={styles.loginForm}>
        <label className={styles.inputLabel}>Message</label>
        <RichTextEditor
          className={styles.textarea}
          value={message}
          onChange={(value) => { setMessage(value) }}
          autoFocus
        />

        <p className={postIsWithinPlainTextCharacterLimit() ? styles.goodCharacterCount : styles.exceededCharacterCount}>{plainTextCharacterCountMessage()}</p>
        <p className={postIsWithinHyperTextCharacterLimit() ? styles.goodCharacterCount : styles.exceededCharacterCount}>{hyperTextCharacterCountMessage()}</p>

        <button id={styles.cancelPostBtn} className={styles.postBtn} onClick={handleCloseModal}>Cancel</button>
        <input type="submit" name="commit" value="Create" id={styles.createPostBtn} className={styles.postBtn} data-disable-with="Create" />
      </form>
    </React.Fragment>
  );
};

export default CommentForm;
