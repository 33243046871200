import React from 'react';
import PropTypes from 'prop-types';

import styles from './EventPost.module.scss';

const EventPost = ({
  imagePath,
  title,
  description,
  startTime,
  venue,
  hasVideoFeed,
  owner,
  eventPath,
  virtualVenuePath,
}) => {
  const goToEvent = () => {
    window.location.assign(eventPath);
  };

  const localeDateTimeString = () => {
    const date = new Date(Date.parse(startTime));
    const timezonePostfix = `${Intl.DateTimeFormat().resolvedOptions().timeZone} (Your Local Time)`;
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()} ${timezonePostfix}`;
  };

  return (
    <div className={styles.eventPost} onClick={goToEvent}>
      <img className={styles.img} src={imagePath} />
      <div className={styles.textContainer}>
        <p className={styles.title}>{title}</p>
        <p className={styles.description}>{description}</p>
        <p className={styles.startTime}>{localeDateTimeString()}</p>
        {!!venue && (<p className={styles.venueName}>{venue.name}</p>)}
        <p className={styles.ownerName}>{owner.name}</p>
      </div>
    </div>
  );
};

EventPost.defaultProps = {
  venue: null,
  hasVideoFeed: false,
};

EventPost.propTypes = {
  imagePath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  venue: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  hasVideoFeed: PropTypes.bool,
  owner: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  eventPath: PropTypes.string.isRequired,
  virtualVenuePath: PropTypes.string.isRequired
};

export default EventPost;
