import IsibuteDocumentComponent from "./IsibuteDocumentComponent";

class IsibuteDocumentSectionTitle extends IsibuteDocumentComponent {
  constructor({
    id,
    unstyledContent,
    styling,
    size,
    ordinal,
    documentComponentType,
    parentDocumentComponentId,
    firstChildDocumentComponentId,
    lastChildDocumentComponentId,
    previousDocumentComponentId,
    nextDocumentComponentId,
    isibuteDocument,
  }) {
    super({
      id,
      unstyledContent,
      styling,
      ordinal,
      documentComponentType,
      parentDocumentComponentId,
      firstChildDocumentComponentId,
      lastChildDocumentComponentId,
      previousDocumentComponentId,
      nextDocumentComponentId,
      isibuteDocument,
    });

    this.size = size;
  }

  asJSON = () => {
    return {
      id: this.id,
      unstyledContent: this.unstyledContent,
      styling: this.styling.map(style => {return {...style}}),
      size: this.size,
      ordinal: this.ordinal,
      documentComponentType: this.documentComponentType,
      parentDocumentComponentId: this.parentDocumentComponentId,
      firstChildDocumentComponentId: this.firstChildDocumentComponentId,
      lastChildDocumentComponentId: this.lastChildDocumentComponentId,
      previousDocumentComponentId: this.previousDocumentComponentId,
      nextDocumentComponentId: this.nextDocumentComponentId,
    };
  };

  deepCopy = () => {
    return new this.constructor({
      id: this.id,
      unstyledContent: this.unstyledContent,
      styling: this.styling.map(style => {return {...style}}),
      size: this.size,
      ordinal: this.ordinal,
      documentComponentType: this.documentComponentType,
      parentDocumentComponentId: this.parentDocumentComponentId,
      firstChildDocumentComponentId: this.firstChildDocumentComponentId,
      lastChildDocumentComponentId: this.lastChildDocumentComponentId,
      previousDocumentComponentId: this.previousDocumentComponentId,
      nextDocumentComponentId: this.nextDocumentComponentId,
      isibuteDocument: this.isibuteDocument,
    });
  };
};

export default IsibuteDocumentSectionTitle;
