import React from "react";

import { RestrictedActionFormProvider } from "./RestrictedActionFormContext";

import RestrictedActionFormFeedback from "./RestrictedActionFormFeedback";
import RestrictedActionForm from "./RestrictedActionForm";

import {
  updateRestrictedAction,
} from './restApi';

import styles from './RestrictedActionForm.module.scss';

const Update = ({ restrictedAction }) => {
  const goToRestrictedAction = (restrictedAction) => {
    location.assign(`/restricted_actions/${restrictedAction.id}`);
  };

  return (
    <RestrictedActionFormProvider>
      <RestrictedActionFormFeedback />
      <section>
        <h2 className={styles.sectionTitle}>
          Update Restricted Action
        </h2>
        <RestrictedActionForm restrictedAction={restrictedAction} onSubmit={updateRestrictedAction}/>
        <button
          className={styles.cancelBtn}
          onClick={() => goToRestrictedAction(restrictedAction)}
        >
          Cancel
        </button>
      </section>
    </RestrictedActionFormProvider>
  );
}

export default Update;
