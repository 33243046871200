import IsibuteDocumentTitle from "./IsibuteDocumentTitle";
import IsibuteDocumentSubtitle from "./IsibuteDocumentSubtitle";
import IsibuteDocumentSectionTitle from "./IsibuteDocumentSectionTitle";
import IsibuteDocumentImage from "./IsibuteDocumentImage";
import IsibuteDocumentParagraph from "./IsibuteDocumentParagraph";
import IsibuteDocumentEmphasis from "./IsibuteDocumentEmphasis";
import IsibuteDocumentCitationsSection from "./IsibuteDocumentCitationsSection";
import IsibuteDocumentCitation from "./IsibuteDocumentCitation";

export const documentComponentFromData = (data) => {
  const registry = {
    'DocumentTitle': IsibuteDocumentTitle,
    'DocumentSubtitle': IsibuteDocumentSubtitle,
    'DocumentSectionTitle': IsibuteDocumentSectionTitle,
    'DocumentImage': IsibuteDocumentImage,
    'DocumentParagraph': IsibuteDocumentParagraph,
    'DocumentEmphasis': IsibuteDocumentEmphasis,
    'DocumentCitationsSection': IsibuteDocumentCitationsSection,
    'DocumentCitation': IsibuteDocumentCitation,
  };

  return new registry[data.documentComponentType](data);
};
