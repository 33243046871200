import React, { useEffect, useState } from 'react';
import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration
} from 'amazon-chime-sdk-js';

import PropTypes from 'prop-types';

import styles from './VirtualVenue.module.scss';

export default function VirtualVenue({meeting, attendee}) {
  const [meetingSession, setMeetingSession] = useState(null);
  const [audioVideoDevicesAreChosen, setAudioVideoDevicesAreChosen] = useState(false);
  const [deviceChangeObserverIsSet, setDeviceChangeObserverIsSet] = useState(false);
  const [audioVideoObserverIsSet, setAudioVideoObserverIsSet] = useState(false);
  const [attendeePresenceCallbackIsSet, setAttendeePresenseCallbackIsSet] = useState(false);
  const [meetingIsActive, setMeetingIsActive] = useState(false);
  const [tileIndex, setTileIndex] = useState(new Array(9).fill(null));

  useEffect(() => {
    const logger = new ConsoleLogger('MyLogger', LogLevel.WARN);

    const deviceController = new DefaultDeviceController(logger);

    const configuration = new MeetingSessionConfiguration({
      "MeetingId": meeting.meetingId,
      "MediaPlacement": {
        "AudioHostUrl": meeting.mediaPlacement.audioHostUrl,
        "ScreenDataUrl": meeting.mediaPlacement.screenDataUrl,
        "ScreenSharingUrl": meeting.mediaPlacement.screenSharingUrl,
        "ScreenViewingUrl": meeting.mediaPlacement.screenViewingUrl,
        "SignalingUrl": meeting.mediaPlacement.signalingUrl,
        "TurnControlUrl": meeting.mediaPlacement.turnControlUrl
      }
    },
    {
      "ExternalUserId": attendee.external_user_id,
      "AttendeeId": attendee.attendee_id,
      "JoinToken": attendee.join_token
    });

    setMeetingSession(
      new DefaultMeetingSession(
        configuration,
        logger,
        deviceController
      )
    );
  }, []);

  useEffect(() => {
    if (!!meetingSession) {
      Promise.all([
        meetingSession.audioVideo.listAudioInputDevices(),
        meetingSession.audioVideo.listAudioOutputDevices(),
        meetingSession.audioVideo.listVideoInputDevices()
      ]).then((devices) => {
        return Promise.all([
          meetingSession.audioVideo.chooseAudioInputDevice(devices[0][0].deviceId),
          meetingSession.audioVideo.chooseAudioOutputDevice(devices[1][0].deviceId),
          meetingSession.audioVideo.chooseVideoInputDevice(devices[2][0].deviceId)
        ]);
      }).then(() => {
        setAudioVideoDevicesAreChosen(true);
      }).catch((error) => {
        console.log(error);
      })
    }
  }, [meetingSession]);

  useEffect(() => {
    if (!!meetingSession) {
      const enumerateDeviceList = (deviceList) => {
        deviceList.forEach(device => {
          console.log(`ID: ${device.deviceId} Label: ${device.label}`);
        })
      };

      meetingSession.audioVideo.addDeviceChangeObserver({
        audioInputsChanged: updatedAudioInputDeviceList => {
          enumerateDeviceList(updatedAudioInputDeviceList);
        },

        audioOutputsChanged: updatedAudioOutputDeviceList => {
          enumerateDeviceList(updatedAudioOutputDeviceList);
        },

        videoInputsChanged: updatedVideoInputDeviceList => {
          enumerateDeviceList(updatedVideoInputDeviceList);
        },

        audioInputMuteStateChanged: (device, muted) => {
          console.log('Device', device, muted ? 'is muted' : 'is unmuted');
        },
      });

      setDeviceChangeObserverIsSet(true);
    }
  }, [meetingSession]);

  useEffect(() => {
    if (!!meetingSession) {
      const videoElements = document.getElementsByClassName(styles.video);

      const lockAndReturnVideoElement = tileId => {
        if (tileIndex.includes(tileId)) {
          const videoElementIndex = tileIndex.indexOf(tileId);
          return videoElements[videoElementIndex];
        }

        if (tileIndex.includes(null)) {
          const availableVideoElementIndex = tileIndex.indexOf(null);
          console.log("preparing to lock video element", availableVideoElementIndex);
          const newTileIndex = tileIndex.slice();
          newTileIndex[availableVideoElementIndex] = tileId;
          setTileIndex(newTileIndex);
          videoElements[availableVideoElementIndex].classList.remove(styles.hidden);
          return videoElements[availableVideoElementIndex];
        }

        throw new Error('Unable to retrieve an available video element');
      };

      const unlockVideoElement = tileId => {
        if (tileIndex.includes(tileId)) {
          const videoElementIndexToUnlock = tileIndex.indexOf(tileId);
          console.log("preparing to unlock video element", videoElementIndexToUnlock);
          const newTileIndex = tileIndex.slice();
          newTileIndex[videoElementIndexToUnlock] = null;
          setTileIndex(newTileIndex);
          videoElements[videoElementIndexToUnlock].classList.add(styles.hidden);
          return;
        }
      };

      const audioVideoObserver = {
        audioVideoDidStart: () => {
          console.log('audio video did start');
        },
        audioVideoDidStop: sessionStatus => {
          console.log('Stopped the audio video with session status code: ', sessionStatus.statusCode());
        },
        audioVideoDidStartConnecting: reconnecting => {
          if (reconnecting) {
            console.log('Having trouble connecting audio video. Attempting to reconnect.');
          }
        },
        videoTileDidUpdate: tileState => {
          console.log(`Video Tile with ID ${tileState.tileId} is being bound to an HTMLVIDEOELEMENT.`);
          meetingSession.audioVideo.bindVideoElement(
            tileState.tileId,
            lockAndReturnVideoElement(tileState.tileId)
          );
        },
        videoTileWasRemoved: tileId => {
          console.log(`Video Tile with ID ${tileId} was removed.`);
          unlockVideoElement(tileId);
        }
      };

      meetingSession.audioVideo.addObserver(audioVideoObserver);

      setAudioVideoObserverIsSet(true);

      return () => { meetingSession.audioVideo.removeObserver(audioVideoObserver) };
    }
  });

  useEffect(() => {
    if (!!meetingSession) {
      meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(() => {
        return (presentAttendeeId, present) => {
          console.log(`Attendee ID: ${presentAttendeeId} Present: ${present}`);
          if (present) {
            console.log(`Attendee ${presentAttendeeId} joined meeting.`);
          } else {
            console.log(`Attendee ${presentAttendeeId} left meeting.`);
          }
        }
      });

      setAttendeePresenseCallbackIsSet(true);
    }
  }, [meetingSession]);

  useEffect(() => {
    if (audioVideoDevicesAreChosen && deviceChangeObserverIsSet && audioVideoObserverIsSet && !meetingIsActive) {
      const audioElement = document.getElementById('audio');
      meetingSession.audioVideo.bindAudioElement(audioElement);

      meetingSession.audioVideo.start();
      meetingSession.audioVideo.startLocalVideoTile();
      setMeetingIsActive(true);
    }
  });

  console.log("meeting session", meetingSession);
  console.log("the index", tileIndex);
  console.log("The meeting is active: ", meetingIsActive);

  const gridStyle = () => {
    const numberOfActiveTiles = tileIndex.filter((tile) => { return tile !== null; }).length;

    if (numberOfActiveTiles === 0) {
      return styles.oneTileGrid;
    } else if (numberOfActiveTiles === 1) {
      return styles.oneTileGrid;
    } else if (numberOfActiveTiles === 2) {
      return styles.twoTileGrid;
    } else if (numberOfActiveTiles === 3) {
      return styles.fourTileGrid;
    } else if (numberOfActiveTiles === 4) {
      return styles.fourTileGrid;
    } else if (numberOfActiveTiles === 5) {
      return styles.sixTileGrid;
    } else if (numberOfActiveTiles === 6) {
      return styles.sixTileGrid;
    } else if (numberOfActiveTiles === 7) {
      return styles.nineTileGrid;
    } else if (numberOfActiveTiles === 8) {
      return styles.nineTileGrid;
    } else if (numberOfActiveTiles === 9) {
      return styles.nineTileGrid;
    }
  };

  return (
    <>
      <div id={styles.videoGrid} class={gridStyle()}>
        {tileIndex.map(() => {
          return (<video class={`${styles.video} ${styles.hidden}`}></video>);
        })}
      </div>
      <audio id="audio"></audio>
    </>
  );
};

VirtualVenue.propTypes = {
  meeting: PropTypes.shape({}),
  attendee: PropTypes.shape({}),
};
