import { HTTPRequestMethods } from "../../Shared/httpRequestMethods";

export const generateAndSendOpenUserInvitation = ({ onResponse, onError, body }) => {
  fetch(
    "/api/open_user_invitations",
    {
      method: HTTPRequestMethods.POST,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const getOpenUserInvitations = ({ onResponse, onError }) => {
  fetch(
    `/api/open_user_invitations`,
    {
      method: HTTPRequestMethods.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const getUserInvitationRequests = ({ onResponse, onError }) => {
  fetch(
    `/api/user_invitation_requests`,
    {
      method: HTTPRequestMethods.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const closeInvitation = ({ invitation, onResponse, onError, body }) => {
  fetch(
    `/api/open_user_invitations/${invitation.id}`,
    {
      method: HTTPRequestMethods.DELETE,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const approveUserInvitationRequest = ({ userInvitationRequest, onResponse, onError, body }) => {
  fetch(
    `/api/user_invitation_requests/${userInvitationRequest.id}/approve`,
    {
      method: HTTPRequestMethods.PATCH,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const denyUserInvitationRequest = ({ userInvitationRequest, onResponse, onError, body }) => {
  fetch(
    `/api/user_invitation_requests/${userInvitationRequest.id}/deny`,
    {
      method: HTTPRequestMethods.PATCH,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};
