import IsibuteDocumentComponent from "./IsibuteDocumentComponent";

class IsibuteDocumentCitation extends IsibuteDocumentComponent {
  keyPath = () => {
    return [this.parentDocumentComponentId, this.id];
  };

  getComponentForArrowLeft = () => {
    const previousDocumentComponent = this.getPreviousDocumentComponent();

    if (previousDocumentComponent) {
      return previousDocumentComponent;
    }

    const parentDocumentComponent = this.getParentDocumentComponent();

    return parentDocumentComponent.getComponentForArrowLeft();
  };

  getPreviousDocumentComponent = () => {
    return this.isibuteDocument.getDocumentComponentByKeyPath({
      keyPath: [this.parentDocumentComponentId, this.previousDocumentComponentId],
    });
  };

  getComponentForArrowRight = () => {
    const nextDocumentComponent = this.getNextDocumentComponent();

    if (nextDocumentComponent) {
      return nextDocumentComponent;
    }

    const parentDocumentComponent = this.getParentDocumentComponent();

    return parentDocumentComponent.getComponentForArrowRight();
  };

  getNextDocumentComponent = () => {
    return this.isibuteDocument.getDocumentComponentByKeyPath({
      keyPath: [this.parentDocumentComponentId, this.nextDocumentComponentId],
    });
  };

  getParentDocumentComponent = () => {
    return this.isibuteDocument.getDocumentComponentByKeyPath({
      keyPath: [this.parentDocumentComponentId],
    });
  }
};

export default IsibuteDocumentCitation;