import React from 'react';
import PropTypes from 'prop-types';
import PostForm from './PostForm';
import Modal from 'react-modal';

import styles from './PostModal.module.scss';

const PostModal = ({ modalOpen, handleCloseWizard }) => {
  return (
    <Modal
      isOpen={modalOpen}
      className={styles.modal}
      overlayClassName={styles.overlay}
      shouldCloseOnOverlayClick={false}
      onRequestClose={handleCloseWizard}
    >
      <PostForm handleCloseWizard={handleCloseWizard}/>
    </Modal>
  );
};

PostModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleCloseWizard: PropTypes.func.isRequired,
};

export default PostModal;
