import React, { useEffect, useState } from "react";

import { getRoles } from './restApi';

import styles from './Index.module.scss';

const Index = () => {
  const [state, setState] = useState({
    roles: [],
    feedbackMessage: null,
    feedbackVariant: null,
  });

  const onResponse = response => setState(prevState => ({ ...prevState, ...response }));

  const onError = feedback => setState(prevState => ({ ...prevState, ...feedback }));

  const goToCreateRole = () => {
    location.assign('/roles/new');
  };

  const goToRole = (role) => {
    location.assign(`/roles/${role.id}`);
  };

  useEffect(() => {
    getRoles({ onResponse, onError });
  }, []);

  return (
    <>
      <section>
        <button
          className={styles.createRolesBtn}
          onClick={() => goToCreateRole()}
        >
          Create Role
        </button>
      </section>
      <section>
        <h2 className={styles.tableTitle}>
          Roles
        </h2>
        {state?.roles?.length ? (
          <table>
            <thead>
              <tr>
                <th>Group Id</th>
                <th>Role Name</th>
              </tr>
            </thead>
            <tbody>
              {state?.roles?.map(role => {
                return (
                  <tr key={role.id}>
                    <td>{role.groupId}</td>
                    <td>{role.roleName}</td>
                    <td>
                      <button
                        className={styles.viewDetailsBtn}
                        onClick={() => goToRole(role)}
                      >
                        View Details
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className={styles.noContentMessage}>No roles.</p>
        )}
      </section>
    </>
  );
};

export default Index;
