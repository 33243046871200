import React from 'react';
import PropTypes from 'prop-types';
import EventForm from './EventForm';
import Modal from 'react-modal';

import styles from './EventModal.module.scss';

const EventModal = ({ wizardOpen, timezones }) => {
  return (
    <Modal
      isOpen={wizardOpen}
      className={styles.modal}
      overlayClassName={styles.overlay}
      shouldCloseOnOverlayClick={true}
    >
      <EventForm timezones={timezones} />
    </Modal>
  );
};

EventModal.propTypes = {
  wizardOpen: PropTypes.bool.isRequired,
};

export default EventModal;
