import { createContext, useContext, useReducer } from 'react';

const UserInvitationsStateContext = createContext(null);

const UserInvitationsDispatchContext = createContext(null);

export function UserInvitationsProvider({ children }) {
  const initialUserInvitationsState = {
    newOpenUserInvitation: {
      firstName: '',
      lastName: '',
      email: '',
    },
    openUserInvitations: [],
    userInvitationRequests: [],
    feedbackMessage: null,
    feedbackVariant: null,
  };

  const [userInvitationsState, userInvitationsDispatch] = useReducer(
    userInvitationsReducer,
    initialUserInvitationsState
  );

  return (
    <UserInvitationsStateContext.Provider value={userInvitationsState}>
      <UserInvitationsDispatchContext.Provider value={userInvitationsDispatch}>
        {children}
      </UserInvitationsDispatchContext.Provider>
    </UserInvitationsStateContext.Provider>
  );
}

export function useUserInvitationsState() {
  return useContext(UserInvitationsStateContext);
}

export function useUserInvitationsDispatch() {
  return useContext(UserInvitationsDispatchContext);
}

function userInvitationsReducer(userInvitationsState, action) {
  switch (action.type) {
    case 'ON_RESPONSE': {
      return { ...userInvitationsState, ...action.response };
    }
    case 'ON_ERROR': {
      return { ...userInvitationsState, ...action.feedback };
    }
    case 'CLEAR_FEEDBACK': {
      const clearedFeedback = {
        feedbackMessage: null,
        feedbackVariant: null
      };

      return { ...userInvitationsState, ...clearedFeedback };
    }
    case 'UPDATE_FIRST_NAME': {
      return {
        ...userInvitationsState,
        newOpenUserInvitation: {
          ...userInvitationsState.newOpenUserInvitation,
          firstName: action.firstName,
        }
      };
    }
    case 'UPDATE_LAST_NAME': {
      return {
        ...userInvitationsState,
        newOpenUserInvitation: {
          ...userInvitationsState.newOpenUserInvitation,
          lastName: action.lastName,
        }
      };
    }
    case 'UPDATE_EMAIL': {
      return {
        ...userInvitationsState,
        newOpenUserInvitation: {
          ...userInvitationsState.newOpenUserInvitation,
          email: action.email,
        }
      };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};
