import React from 'react';

import {
  useRoleFormState,
  useRoleFormDispatch,
} from './RoleFormContext';

import Feedback from '../../Feedback/components/Feedback';

const RoleFormFeedback = () => {
  const roleFormState = useRoleFormState();

  const roleFormDispatch = useRoleFormDispatch();

  const clearFeedback = () => {
    roleFormDispatch({ type: 'CLEAR_FEEDBACK' });
  };

  return (
    <Feedback
      message={roleFormState?.feedbackMessage}
      variant={roleFormState?.feedbackVariant}
      closeFeedback={clearFeedback}
    />
  );
};

export default RoleFormFeedback;
