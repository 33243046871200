import React, { useEffect } from 'react';

import {
  useUserInvitationsState,
  useUserInvitationsDispatch
} from './UserInvitationsContext';

import {
  approveUserInvitationRequest,
  denyUserInvitationRequest,
  getUserInvitationRequests,
} from './restApi';

import styles from './UserInvitations.module.scss';

const UserInvitationRequestsTable = () => {
  const userInvitationsState = useUserInvitationsState();

  const userInvitationsDispatch = useUserInvitationsDispatch();

  const authenticityToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

  const onResponse = response => userInvitationsDispatch({ type: 'ON_RESPONSE', response });

  const onError = feedback => userInvitationsDispatch({ type: 'ON_ERROR', feedback });

  useEffect(() => {
    getUserInvitationRequests({ onResponse, onError });
  }, []);

  return userInvitationsState?.userInvitationRequests?.length ? (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Status</th>
          <th>Created At</th>
        </tr>
      </thead>
      <tbody>
        {userInvitationsState?.userInvitationRequests?.map(request => {
          return (
            <tr key={request.id}>
              <td>{request.firstName} {request.lastName}</td>
              <td>{request.email}</td>
              <td>{request.aasmState}</td>
              <td>{request.createdAt}</td>
              <td>
                <button
                  onClick={() => {
                      approveUserInvitationRequest(({
                        userInvitationRequest: request,
                        onResponse,
                        onError,
                        body: {
                          authenticity_token: authenticityToken,
                        },
                      }));
                    }
                  }
                  className={styles.approveInvitationRequestBtn}
                >
                  Approve
                </button>
              </td>
              <td>
                <button
                  onClick={() => {
                      denyUserInvitationRequest(({
                        userInvitationRequest: request,
                        onResponse,
                        onError,
                        body: {
                          authenticity_token: authenticityToken,
                        },
                      }));
                    }
                  }
                  className={styles.denyInvitationRequestBtn}
                >
                  Deny
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : (
    <p className={styles.noContentMessage}>No user invitation requests.</p>
  );
};

export default UserInvitationRequestsTable;
