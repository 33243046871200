import React, { useEffect } from 'react';

import styles from './Feedback.module.scss';

const Feedback = ({ message, variant, reverseLastAction, closeFeedback }) => {
  const clearFeedbackInLocalStorageAndCloseFeedback = () => {
    localStorage.removeItem("IsibuteFeedbackMessage");
    localStorage.removeItem("IsibuteFeedbackVariant");
    closeFeedback();
  };

  useEffect(() => {
    if (variant === 'success') {
      setTimeout(() => {
        clearFeedbackInLocalStorageAndCloseFeedback();
      }, 10000);
    }
  })

  return (
    <React.Fragment>
      {message && (
        <div className={`${styles.feedback} ${styles[variant]}`}>
          <div className={styles.messageContainer}>
            <p className={`${styles.message} ${styles[variant]}`}>{message}</p>
          </div>
          {reverseLastAction && <button className={styles.undoButton} onClick={reverseLastAction}>Undo</button>}
          {closeFeedback && <button className={styles.closeButton} onClick={clearFeedbackInLocalStorageAndCloseFeedback}>Close</button>}
        </div>
      )}
    </React.Fragment>
  );
};

export default Feedback;