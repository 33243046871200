import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { updateCurrentAccountPassword } from './restApi';

import styles from './PasswordUpdateForm.module.scss';

const PasswordUpdateForm = ({ handleClosePasswordUpdateModal }) => {
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleCurrentPasswordTextChange = (e) => {
    setCurrentPassword(e.target.value);
  };

  const handleNewPasswordTextChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleNewPasswordConfirmationTextChange = (e) => {
    setNewPasswordConfirmation(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    updateCurrentAccountPassword({
      onResponse: (data) => {
        if (data.feedbackVariant === 'success') {
          handleClosePasswordUpdateModal();
        } else {
          setErrorMessage(data.error_message);
        }
      },
      onError: ({ feedbackMessage, feedbackVariant }) => setErrorMessage(feedbackMessage),
      body: {
        current_password: currentPassword,
        new_password: newPassword,
        new_password_confirmation: newPasswordConfirmation,
        authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content")
      },
    });
  };

  return (
    <React.Fragment>
      { errorMessage && <h3 className="error"> { errorMessage } </h3> }

      <form onSubmit={onSubmit} id={styles.loginForm}>
        <label className={styles.inputLabel} htmlFor="password">Current Password</label>
        <input
          className={styles.input}
          type="password" name="session[current_password]"
          id="current_password"
          onChange={handleCurrentPasswordTextChange}
        />

        <label className={styles.inputLabel} htmlFor="password">New Password</label>
        <input
          className={styles.input}
          type="password"
          name="session[new_password]"
          id="new_password"
          onChange={handleNewPasswordTextChange}
        />

        <label className={styles.inputLabel} htmlFor="password">New Password Confirmation</label>
        <input
          className={styles.input}
          type="password"
          name="session[new_password_confirmation]"
          id="new_password_confirmation"
          onChange={handleNewPasswordConfirmationTextChange}
        />

        <input type="submit" name="commit" value="Update Password" className={styles.loginBtn} data-disable-with="Update Password" />
      </form>
    </React.Fragment>
  );
};

PasswordUpdateForm.propTypes = {
  handleClosePasswordUpdateModal: PropTypes.func.isRequired,
};

export default PasswordUpdateForm;
