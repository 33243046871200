import { HTTPRequestMethods } from "../../Shared/httpRequestMethods";
import { convertObjectKeysToSnakeCase } from "../../Shared/ConvertObjectKeysToSnakeCase";

export const getDocument = ({ documentId, onResponse, onError }) => {
  fetch(
    `/api/documents/${documentId}`,
    {
      method: HTTPRequestMethods.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const saveDocument = ({ onResponse, onError, body }) => {
  fetch(
    `/api/documents`,
    {
      method: HTTPRequestMethods.POST,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(convertObjectKeysToSnakeCase(body)),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const getDocuments = ({ pageNumber, documentState, onResponse, onError }) => {
  fetch(
    `/api/documents?page_number=${pageNumber}&document_state=${documentState}`,
    {
      method: HTTPRequestMethods.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const seedDocument = ({ onResponse, onError, body }) => {
  fetch(
    "/api/documents/seed",
    {
      method: HTTPRequestMethods.PUT,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};
