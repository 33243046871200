import { documentComponentFromData } from "./DocumentComponentRegistry";

class IsibuteDocumentComponent {
  constructor({
    id,
    unstyledContent,
    styling,
    ordinal,
    documentComponentType,
    parentDocumentComponentId,
    firstChildDocumentComponentId,
    lastChildDocumentComponentId,
    previousDocumentComponentId,
    nextDocumentComponentId,
    isibuteDocument,
  }) {
    this.id = id;
    this.unstyledContent = unstyledContent;
    this.styling = styling;
    this.ordinal = ordinal;
    this.documentComponentType = documentComponentType;
    this.parentDocumentComponentId = parentDocumentComponentId;
    this.firstChildDocumentComponentId = firstChildDocumentComponentId;
    this.lastChildDocumentComponentId = lastChildDocumentComponentId;
    this.previousDocumentComponentId = previousDocumentComponentId;
    this.nextDocumentComponentId = nextDocumentComponentId;
    this.isibuteDocument = isibuteDocument;
  }

  asJSON = () => {
    return {
      id: this.id,
      unstyledContent: this.unstyledContent,
      styling: this.styling.map(style => {return {...style}}),
      ordinal: this.ordinal,
      documentComponentType: this.documentComponentType,
      parentDocumentComponentId: this.parentDocumentComponentId,
      firstChildDocumentComponentId: this.firstChildDocumentComponentId,
      lastChildDocumentComponentId: this.lastChildDocumentComponentId,
      previousDocumentComponentId: this.previousDocumentComponentId,
      nextDocumentComponentId: this.nextDocumentComponentId,
    };
  };

  deepCopy = () => {
    return new this.constructor({
      id: this.id,
      unstyledContent: this.unstyledContent,
      styling: this.styling.map(style => {return {...style}}),
      ordinal: this.ordinal,
      documentComponentType: this.documentComponentType,
      parentDocumentComponentId: this.parentDocumentComponentId,
      firstChildDocumentComponentId: this.firstChildDocumentComponentId,
      lastChildDocumentComponentId: this.lastChildDocumentComponentId,
      previousDocumentComponentId: this.previousDocumentComponentId,
      nextDocumentComponentId: this.nextDocumentComponentId,
      isibuteDocument: this.isibuteDocument,
    });
  };

  titleizedCopy = () => {
    return documentComponentFromData({
      id: this.id,
      unstyledContent: this.unstyledContent,
      styling: this.styling.map(style => {return {...style}}),
      ordinal: this.ordinal,
      documentComponentType: 'DocumentTitle',
      parentDocumentComponentId: this.parentDocumentComponentId,
      firstChildDocumentComponentId: this.firstChildDocumentComponentId,
      lastChildDocumentComponentId: this.lastChildDocumentComponentId,
      previousDocumentComponentId: this.previousDocumentComponentId,
      nextDocumentComponentId: this.nextDocumentComponentId,
      isibuteDocument: this.isibuteDocument,
    });
  };

  subtitleizedCopy = () => {
    return documentComponentFromData({
      id: this.id,
      unstyledContent: this.unstyledContent,
      styling: this.styling.map(style => {return {...style}}),
      ordinal: this.ordinal,
      documentComponentType: 'DocumentSubtitle',
      parentDocumentComponentId: this.parentDocumentComponentId,
      firstChildDocumentComponentId: this.firstChildDocumentComponentId,
      lastChildDocumentComponentId: this.lastChildDocumentComponentId,
      previousDocumentComponentId: this.previousDocumentComponentId,
      nextDocumentComponentId: this.nextDocumentComponentId,
      isibuteDocument: this.isibuteDocument,
    });
  };

  sectionTitleizedCopy = ({ size }) => {
    return documentComponentFromData({
      id: this.id,
      unstyledContent: this.unstyledContent,
      styling: this.styling.map(style => {return {...style}}),
      ordinal: this.ordinal,
      documentComponentType: 'DocumentSectionTitle',
      parentDocumentComponentId: this.parentDocumentComponentId,
      firstChildDocumentComponentId: this.firstChildDocumentComponentId,
      lastChildDocumentComponentId: this.lastChildDocumentComponentId,
      previousDocumentComponentId: this.previousDocumentComponentId,
      nextDocumentComponentId: this.nextDocumentComponentId,
      isibuteDocument: this.isibuteDocument,
      size,
    });
  };

  emphasizedCopy = () => {
    return documentComponentFromData({
      id: this.id,
      unstyledContent: this.unstyledContent,
      styling: this.styling.map(style => {return {...style}}),
      ordinal: this.ordinal,
      documentComponentType: 'DocumentEmphasis',
      parentDocumentComponentId: this.parentDocumentComponentId,
      firstChildDocumentComponentId: this.firstChildDocumentComponentId,
      lastChildDocumentComponentId: this.lastChildDocumentComponentId,
      previousDocumentComponentId: this.previousDocumentComponentId,
      nextDocumentComponentId: this.nextDocumentComponentId,
      isibuteDocument: this.isibuteDocument,
    });
  };

  citationizedCopy = ({ parentDocumentComponentId }) => {
    return documentComponentFromData({
      id: this.id,
      unstyledContent: this.unstyledContent,
      styling: this.styling.map(style => {return {...style}}),
      ordinal: this.ordinal, // this needs to be updated
      documentComponentType: 'DocumentCitation',
      parentDocumentComponentId,
      firstChildDocumentComponentId: this.firstChildDocumentComponentId,
      lastChildDocumentComponentId: this.lastChildDocumentComponentId,
      previousDocumentComponentId: this.previousDocumentComponentId,
      nextDocumentComponentId: this.nextDocumentComponentId,
      isibuteDocument: this.isibuteDocument,
    });
  };

  keyPath = () => {
    return [this.id];
  };

  getComponentForArrowLeft = () => {
    const previousDocumentComponent = this.getPreviousDocumentComponent();

    if (previousDocumentComponent?.lastChildDocumentComponentId) {
      return this.isibuteDocument.getDocumentComponentByKeyPath({
        keyPath: [
          previousDocumentComponent.id,
          previousDocumentComponent.lastChildDocumentComponentId,
        ],
      });
    }

    return previousDocumentComponent;
  };

  getPreviousDocumentComponent = () => {
    return this.isibuteDocument.getDocumentComponentByKeyPath({
      keyPath: [this.previousDocumentComponentId],
    });
  };

  getComponentForArrowRight = () => {
    const nextDocumentComponent = this.getNextDocumentComponent();

    if (nextDocumentComponent?.firstChildDocumentComponentId) {
      return this.isibuteDocument.getDocumentComponentByKeyPath({
        keyPath: [
          nextDocumentComponent.id,
          nextDocumentComponent.firstChildDocumentComponentId,
        ],
      });
    }

    return nextDocumentComponent;
  };

  getNextDocumentComponent = () => {
    return this.isibuteDocument.getDocumentComponentByKeyPath({
      keyPath: [this.nextDocumentComponentId],
    });
  };

  applyStyle = (instruction) => {
    let iteratorIndex = 0;

    while (!!this.styling[iteratorIndex]) {
      let style = this.styling[iteratorIndex];

      if (instruction.end < style.start || instruction.start > style.end) {
        iteratorIndex += 1;
        continue;
      }

      style = this.styling.splice(iteratorIndex, 1)[0];

      const newStyleWithApplicableRange = {
        start: instruction.start,
        end: instruction.end,
        types: instruction.types,
        href: instruction.href,
      };

      if (newStyleWithApplicableRange.start < style.start) {
        newStyleWithApplicableRange.start = style.start;
      }

      if (newStyleWithApplicableRange.end > style.end) {
        newStyleWithApplicableRange.end = style.end;
      }

      if (style.start < newStyleWithApplicableRange.start) {
        this.styling.splice(
          iteratorIndex,
          0,
          {
            start: style.start,
            end: newStyleWithApplicableRange.start - 1,
            types: style.types,
          }
        );

        iteratorIndex += 1;
      }

      const newStyleMergedWithPreexistingStyle = {
        start: newStyleWithApplicableRange.start,
        end: newStyleWithApplicableRange.end,
        types: this.mergeStyleTypes(style.types, newStyleWithApplicableRange.types),
        href: newStyleWithApplicableRange.href,
      };

      Object.keys(newStyleMergedWithPreexistingStyle).forEach(key => {
        if (newStyleMergedWithPreexistingStyle[key] === undefined) {
          delete newStyleMergedWithPreexistingStyle[key];
        }
      });

      this.styling.splice(
        iteratorIndex,
        0,
        newStyleMergedWithPreexistingStyle,
      );

      iteratorIndex += 1;

      if (style.end > newStyleWithApplicableRange.end) {
        this.styling.splice(
          iteratorIndex,
          0,
          {
            start: newStyleWithApplicableRange.end + 1,
            end: style.end,
            types: style.types,
          }
        );

        iteratorIndex += 1;
      }
    }
  };

  mergeStyleTypes = (preexistingTypes, newTypes) => {
    if ((preexistingTypes.length == 1) && (preexistingTypes[0] == 'none')) {
      return newTypes;
    }

    const index = preexistingTypes.indexOf(newTypes[0]);

    if (index > -1) {
      preexistingTypes.splice(index, 1);
      return preexistingTypes.length > 0 ? preexistingTypes : ['none'];
    }

    return preexistingTypes.concat(newTypes);
  };

  splitStyling = ({ splitIndex }) => {
    const firstHalfOfStyling = [];
    const secondHalfOfStyling = [];

    this.styling.forEach((style) => {
      if (style.start < splitIndex && style.end < splitIndex) {
        firstHalfOfStyling.push(style);
      }

      if (style.start < splitIndex && splitIndex <= style.end) {
        firstHalfOfStyling.push({
          start: style.start,
          end: splitIndex - 1,
          types: style.types,
        });
        secondHalfOfStyling.push({
          start: 0,
          end: style.end - splitIndex + 1,
          types: style.types,
        });
      }

      if (splitIndex == style.start && splitIndex <= style.end) {
        secondHalfOfStyling.push({
          start: 0,
          end: style.end - splitIndex + 1,
          types: style.types,
        });
      }

      if (splitIndex < style.start && splitIndex < style.end) {
        secondHalfOfStyling.push({
          start: style.start - splitIndex + 1,
          end: style.end - splitIndex + 1,
          types: style.types,
        });
      }
    });

    if (secondHalfOfStyling.length == 0) {
      secondHalfOfStyling.push({
        start: 0,
        end: 0,
        types: ['none'],
      });
    }

    return {
      firstHalfOfStyling,
      secondHalfOfStyling,
    };
  };

  joinStyling = ({ firstHalfOfStyling, secondHalfOfStyling }) => {
    const joinedStyling = [];

    firstHalfOfStyling.forEach((style) => {
      joinedStyling.push(style);
    });

    const endOfFirstHalfOfStyling = firstHalfOfStyling.sort((thisStyle, thatStyle) => {
      return thisStyle.end - thatStyle.end;
    }).pop().end;

    secondHalfOfStyling.forEach((style) => {
      if (style.start == 0) {
        joinedStyling.push({
          start: style.start + endOfFirstHalfOfStyling + 1,
          end: style.end + endOfFirstHalfOfStyling,
          types: style.types,
        });
      } else {
        joinedStyling.push({
          start: style.start + endOfFirstHalfOfStyling,
          end: style.end + endOfFirstHalfOfStyling,
          types: style.types,
        });
      }
    });

    return joinedStyling;
  };

  joinUnstyledContent = ({ firstHalfOfUnstyledConent, secondHalfOfUnstyledContent }) => {
    return firstHalfOfUnstyledConent + secondHalfOfUnstyledContent.substring(1);
  };
};

export default IsibuteDocumentComponent;