import PropTypes from 'prop-types';

const sharedPropsType = {
  authenticatedUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  sharedAssetsPaths: PropTypes.shape({}).isRequired,
}

export default sharedPropsType;
