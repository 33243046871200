import React from 'react';

const Footer = () => {
  return (
    <footer>
      <nav>
        <a href="/legal">Legal</a>
      </nav>
    </footer>
  );
};

export default Footer;
