import { createContext, useContext, useReducer } from 'react';

const RestrictedActionFormStateContext = createContext(null);

const RestrictedActionFormDispatchContext = createContext(null);

export function RestrictedActionFormProvider({
  children,
  initialRestrictedActionFormState = {
    restrictedActionForm: {
      groupId: '',
      restrictedActionName: '',
      resourceId: '',
      resourceType: '',
      applyToAllUsers: false,
    },
    feedbackMessage: null,
    feedbackVariant: null,
  }
}) {
  const [restrictedActionFormState, restrictedActionFormDispatch] = useReducer(
    restrictedActionFormReducer,
    initialRestrictedActionFormState
  );

  return (
    <RestrictedActionFormStateContext.Provider value={restrictedActionFormState}>
      <RestrictedActionFormDispatchContext.Provider value={restrictedActionFormDispatch}>
        {children}
      </RestrictedActionFormDispatchContext.Provider>
    </RestrictedActionFormStateContext.Provider>
  );
}

export function useRestrictedActionFormState() {
  return useContext(RestrictedActionFormStateContext);
}

export function useRestrictedActionFormDispatch() {
  return useContext(RestrictedActionFormDispatchContext);
}

function restrictedActionFormReducer(restrictedActionFormState, action) {
  switch (action.type) {
    case 'ON_RESPONSE': {
      return { ...restrictedActionFormState, ...action.response };
    }
    case 'ON_ERROR': {
      return { ...restrictedActionFormState, ...action.feedback };
    }
    case 'CLEAR_FEEDBACK': {
      const clearedFeedback = {
        feedbackMessage: null,
        feedbackVariant: null
      };

      return { ...restrictedActionFormState, ...clearedFeedback };
    }
    case 'UPDATE_FORM': {
      return {
        ...restrictedActionFormState,
        restrictedActionForm: action.restrictedAction,
      };
    }
    case 'UPDATE_GROUP_ID': {
      return {
        ...restrictedActionFormState,
        restrictedActionForm: {
          ...restrictedActionFormState.restrictedActionForm,
          groupId: action.groupId,
        }
      };
    }
    case 'UPDATE_RESTRICTED_ACTION_NAME': {
      return {
        ...restrictedActionFormState,
        restrictedActionForm: {
          ...restrictedActionFormState.restrictedActionForm,
          restrictedActionName: action.restrictedActionName,
        }
      };
    }
    case 'UPDATE_RESOURCE_ID': {
      return {
        ...restrictedActionFormState,
        restrictedActionForm: {
          ...restrictedActionFormState.restrictedActionForm,
          resourceId: action.resourceId,
        }
      };
    }
    case 'UPDATE_RESOURCE_TYPE': {
      return {
        ...restrictedActionFormState,
        restrictedActionForm: {
          ...restrictedActionFormState.restrictedActionForm,
          resourceType: action.resourceType,
        }
      };
    }
    case 'UPDATE_APPLY_TO_ALL_USERS': {
      return {
        ...restrictedActionFormState,
        restrictedActionForm: {
          ...restrictedActionFormState.restrictedActionForm,
          applyToAllUsers: action.applyToAllUsers,
        }
      };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};
