import PropTypes from 'prop-types';
import React from 'react';

import styles from './CareerBuilderRequestReceived.module.scss';

const CareerBuilderRequestReceived = ({ isActive, sharedAssetsPaths, closeCareerBuilderRequestWizard }) => {
  return isActive ? (
    <React.Fragment>
      <div id={styles.xComponentContainer}>
        <img src={sharedAssetsPaths.xComponent} id={styles.xComponent} onClick={closeCareerBuilderRequestWizard}></img>
      </div>
      <section id={styles.mainContent}>
        <div>
          <img id={styles.partyPopper} src={sharedAssetsPaths.partyPopper256}></img>
        </div>
        <div id={styles.messageContainer}>
          <p id={styles.message}>Thanks for your request. We’ll be in touch with you about building your career.</p>
        </div>
        <div>
          <p id={styles.homeCTA} onClick={closeCareerBuilderRequestWizard}>Go Back Home</p>
        </div>
      </section>
    </React.Fragment>
  ) : (
    <></>
  );
};

CareerBuilderRequestReceived.propTypes = {
  sharedAssetsPaths: PropTypes.shape({}).isRequired,
};

export default CareerBuilderRequestReceived;
