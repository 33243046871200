import React from 'react';

import styles from './LegalIndex.module.scss';

const LegalIndex = () => {
  return (
    <React.Fragment>
      <div class={styles.legalContainer}>
        <div class={styles.legalDocumentLinkContainer}>
          <a class={styles.legalDocumentLink} href="/legal/terms_of_service">Terms Of Service</a>
        </div>
        <div class={styles.legalDocumentLinkContainer}>
          <a class={styles.legalDocumentLink} href="/legal/privacy_policy">Privacy Policy</a>
        </div>
        <div class={styles.legalDocumentLinkContainer}>
          <a class={styles.legalDocumentLink} href="/legal/cookie_policy">Cookie Policy</a>
        </div>
        <div class={styles.legalDocumentLinkContainer}>
          <a class={styles.legalDocumentLink} href="/legal/disclaimer">Disclaimer</a>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LegalIndex;
