import { createContext, useContext, useReducer } from 'react';

const RoleFormStateContext = createContext(null);

const RoleFormDispatchContext = createContext(null);

export function RoleFormProvider({
  children,
  initialRoleFormState = {
    roleForm: {
      groupId: '',
      roleName: '',
    },
    feedbackMessage: null,
    feedbackVariant: null,
  }
}) {
  const [roleFormState, roleFormDispatch] = useReducer(
    roleFormReducer,
    initialRoleFormState
  );

  return (
    <RoleFormStateContext.Provider value={roleFormState}>
      <RoleFormDispatchContext.Provider value={roleFormDispatch}>
        {children}
      </RoleFormDispatchContext.Provider>
    </RoleFormStateContext.Provider>
  );
}

export function useRoleFormState() {
  return useContext(RoleFormStateContext);
}

export function useRoleFormDispatch() {
  return useContext(RoleFormDispatchContext);
}

function roleFormReducer(roleFormState, action) {
  switch (action.type) {
    case 'ON_RESPONSE': {
      return { ...roleFormState, ...action.response };
    }
    case 'ON_ERROR': {
      return { ...roleFormState, ...action.feedback };
    }
    case 'CLEAR_FEEDBACK': {
      const clearedFeedback = {
        feedbackMessage: null,
        feedbackVariant: null
      };

      return { ...roleFormState, ...clearedFeedback };
    }
    case 'UPDATE_FORM': {
      return {
        ...roleFormState,
        roleForm: action.role,
      };
    }
    case 'UPDATE_GROUP_ID': {
      return {
        ...roleFormState,
        roleForm: {
          ...roleFormState.roleForm,
          groupId: action.groupId,
        }
      };
    }
    case 'UPDATE_ROLE_NAME': {
      return {
        ...roleFormState,
        roleForm: {
          ...roleFormState.roleForm,
          roleName: action.roleName,
        }
      };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};
