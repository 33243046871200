import React, { useEffect, useState } from 'react';

import { getUsers } from './restApi';

import styles from './Index.module.scss';

const Index = () => {
  const [state, setState] = useState({
    users: [],
    feedbackMessage: null,
    feedbackVariant: null,
  });

  const onResponse = response => setState(prevState => ({ ...prevState, ...response }));

  const onError = feedback => setState(prevState => ({ ...prevState, ...feedback }));

  useEffect(() => {
    getUsers({ onResponse, onError });
  }, []);

  return (
    <>
      <section id={styles.userCountSection}>
        <p id={styles.userCountText}>{state.users.length} {state.users.length === 1 ? "user" : "users"}</p>
      </section>
      <section>
        <h2 className={styles.sectionTitle}>Users</h2>
        {state?.users?.length ? (
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Primary Email</th>
                <th>Is A Bot</th>
              </tr>
            </thead>
            <tbody>
              {state?.users?.map(user => {
                return (
                  <tr key={user.id}>
                    <td><img id={styles.profilePicture} src={user.profilePicturePath}></img></td>
                    <td>{user.name}</td>
                    <td>{user.primaryEmail}</td>
                    <td>{user.isABot.toString()}</td>
                    <td>
                      <button
                        className={styles.viewDetailsBtn}
                        onClick={() => goTouser(user)}
                      >
                        <a className={styles.userLink} href={user.path}>View Details</a>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className={styles.noContentMessage}>No users.</p>
        )}
      </section>
    </>
  );
};

export default Index;
