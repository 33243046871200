import React from 'react';

import {
  useUserInvitationsState,
  useUserInvitationsDispatch
} from './UserInvitationsContext';

import Feedback from '../../Feedback/components/Feedback';

const UserInvitationsFeedback = () => {
  const userInvitationsState = useUserInvitationsState();

  const userInvitationsDispatch = useUserInvitationsDispatch();

  const clearFeedback = () => {
    userInvitationsDispatch({ type: 'CLEAR_FEEDBACK' });
  };

  return (
    <Feedback
      message={userInvitationsState?.feedbackMessage}
      variant={userInvitationsState?.feedbackVariant}
      closeFeedback={clearFeedback}
    />
  );
};

export default UserInvitationsFeedback;
