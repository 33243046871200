export const convertObjectKeysToSnakeCase = (obj) => {
  if (typeof obj !== 'object') {
    return obj;
  }

  if (obj === null) {
    return obj;
  }

  if (obj === undefined) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((element) => convertObjectKeysToSnakeCase(element));
  } else {
    const newObj = {};

    for (const key in obj) {
      const snakeKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
      newObj[snakeKey] = convertObjectKeysToSnakeCase(obj[key]);
    }

    return newObj;
  }
};
