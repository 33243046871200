import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import EventModal from '../components/EventModal';
import EventPost from '../components/EventPost';

import { getEvents } from './restApi';

import styles from './Events.module.scss';

export default function Events({ timezones }) {
  const [eventData, setEventData] = useState([]);
  const [lastPageViewed, setLastPageViewed] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [canViewMoreEvents, setCanViewMoreEvents] = useState(true);
  const [eventWizardOpen, setEventWizardOpen] = useState(false);

  const openEventWizard = (e) => {
    e.preventDefault();
    setEventWizardOpen(true);
  };

  const events = () => {
    return eventData.map(event => {
      return (
        <EventPost
          key={event.id}
          imagePath={event.imagePath}
          title={event.title}
          description={event.description}
          startTime={event.startTime}
          hasVideoFeed={event.hasVirtualVenue}
          owner={event.owner}
          eventPath={event.path}
          virtualVenuePath={event.virtualVenuePath}
        />
      );
    });
  }

  useEffect(() => {
    getEvents({
      lastPageViewed,
      onResponse: (data) => {
        if (data.feedbackVariant === 'success') {
          setEventData(eventData.slice().concat(data.events));
          setLastPageViewed(data.pageNumber);
          setCanViewMoreEvents(data.canViewMoreEvents);
        } else {
          setErrorMessage(data.message);
        }
      },
      onError: ({ feedbackMessage, feedbackVariant }) => setErrorMessage(feedbackMessage),
    });
  }, []);

  return (
    <React.Fragment>
      <div id={styles.infinitScrollContainer}>
        <InfiniteScroll
          dataLength={eventData.length}
          next={() => {
            getEvents({
              lastPageViewed,
              onResponse: (data) => {
                if (data.feedbackVariant === 'success') {
                  setEventData(eventData.slice().concat(data.events));
                  setLastPageViewed(data.pageNumber);
                  setCanViewMoreEvents(data.canViewMoreEvents);
                } else {
                  setErrorMessage(data.message);
                }
              },
              onError: ({ feedbackMessage, feedbackVariant }) => setErrorMessage(feedbackMessage),
            });
          }}
          hasMore={canViewMoreEvents}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>You've seen all the events</b>
            </p>
          }
          scrollableTarget={styles.infinitScrollContainer}
        >
          <div id={styles.createEventsContainer}>
            <button id={styles.createEventsBtn} onClick={openEventWizard}>Make an event</button>
          </div>
          {events()}
        </InfiniteScroll>
      </div>
      <EventModal
        wizardOpen={eventWizardOpen}
        timezones={timezones}
      />
    </React.Fragment>
  );
}
