import React from 'react';

import {
  useRestrictedActionFormState,
  useRestrictedActionFormDispatch,
} from './RestrictedActionFormContext';

import Feedback from '../../Feedback/components/Feedback';

const RestrictedActionFormFeedback = () => {
  const restrictedActionFormState = useRestrictedActionFormState();

  const restrictedActionFormDispatch = useRestrictedActionFormDispatch();

  const clearFeedback = () => {
    restrictedActionFormDispatch({ type: 'CLEAR_FEEDBACK' });
  };

  return (
    <Feedback
      message={restrictedActionFormState?.feedbackMessage}
      variant={restrictedActionFormState?.feedbackVariant}
      closeFeedback={clearFeedback}
    />
  );
};

export default RestrictedActionFormFeedback;
