import React, { useEffect } from 'react';

import {
  useRoleFormState,
  useRoleFormDispatch,
} from './RoleFormContext';

import styles from './RoleForm.module.scss';

const RoleForm = ({ role, onSubmit }) => {
  const roleFormState = useRoleFormState();

  const roleFormDispatch = useRoleFormDispatch();

  const authenticityToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

  const onResponse = (response) => {
    if (response.feedbackVariant === 'success') {
      localStorage.setItem("IsibuteFeedbackMessage", response.feedbackMessage);
      localStorage.setItem("IsibuteFeedbackVariant", response.feedbackVariant);
      location.assign(`/roles/${role.id}`);
    } else {
      roleFormDispatch({ type: 'ON_RESPONSE', response });
    }
  };

  const onError = feedback => roleFormDispatch({ type: 'ON_ERROR', feedback });

  useEffect(() => {
    if (role) {
      roleFormDispatch({ type: 'UPDATE_FORM', role });
    }
  }, []);

  return (
    <form id={styles.roleForm}>
      <input
        className={styles.input}
        type="text"
        id="groupId"
        placeholder="Group Id:"
        onChange={
          (e) => {
            const groupId = e.target.value;
            roleFormDispatch({ type: 'UPDATE_GROUP_ID', groupId });
          }
        }
        value={roleFormState?.roleForm?.groupId}
      />

      <input
        className={styles.input}
        type="text"
        id="roleName"
        placeholder="Restricted Action Name:"
        onChange={
          (e) => {
            const roleName = e.target.value;
            roleFormDispatch({ type: 'UPDATE_ROLE_NAME', roleName });
          }
        }
        value={roleFormState?.roleForm?.roleName}
      />

      <button
        type="button"
        className={styles.submitBtn}
        onClick={() => {
          onSubmit({
            role,
            onResponse,
            onError,
            body: {
              roleForm: roleFormState?.roleForm,
              authenticity_token: authenticityToken,
            },
          });
        }}
      >
        {!!role ? 'Update Role' : 'Create Role'}
      </button>
    </form>
  );
};

export default RoleForm;
