import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import StepWizard from 'react-step-wizard';
import CareerBuilderRequestForm from './CareerBuilderRequestForm';
import CareerBuilderRequestReceived from './CareerBuilderRequestReceived';
import Feedback from '../../Feedback/components/Feedback';

import styles from './CareerBuilderRequestWizard.module.scss';

const CareerBuilderRequestWizard = ({
  sharedAssetsPaths,
  showCareerBuilderRequestWizard,
  closeCareerBuilderRequestWizard
}) => {
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [feedbackVariant, setFeedbackVariant] = useState(null);

  const clearFeedback = () => {
    setFeedbackMessage(null);
    setFeedbackVariant(null);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={showCareerBuilderRequestWizard}
        className={styles.modal}
        overlayClassName={styles.overlay}
        contentLabel="Career Builder Request Wizard"
        onRequestClose={closeCareerBuilderRequestWizard}
      >
        <StepWizard>
          <CareerBuilderRequestForm
            closeCareerBuilderRequestWizard={closeCareerBuilderRequestWizard}
            sharedAssetsPaths={sharedAssetsPaths}
            setFeedbackMessage={setFeedbackMessage}
            setFeedbackVariant={setFeedbackVariant}
          />
          <CareerBuilderRequestReceived
            closeCareerBuilderRequestWizard={closeCareerBuilderRequestWizard}
            sharedAssetsPaths={sharedAssetsPaths}
          />
        </StepWizard>
      </Modal>
      <Feedback
        message={feedbackMessage}
        variant={feedbackVariant}
        closeFeedback={clearFeedback}
      />
    </React.Fragment>
  );
};

CareerBuilderRequestWizard.propTypes = {
  sharedAssetsPaths: PropTypes.shape({}).isRequired,
};

export default CareerBuilderRequestWizard;
