import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { createCareerBuilderRequest } from './restApi';

import styles from './CareerBuilderRequestForm.module.scss';

const CareerBuilderRequestForm = ({
  closeCareerBuilderRequestWizard,
  sharedAssetsPaths,
  isActive,
  nextStep,
  setFeedbackMessage,
  setFeedbackVariant
}) => {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [linkedInProfileUrl, setLinkedInProfileUrl] = useState(null);
  const [personalWebsiteOrBlogUrl, setPersonalWebsiteOrBlogUrl] = useState(null);

  const handleFirstNameTextChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameTextChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailTextChange = (e) => {
    setEmail(e.target.value);
  };

  const handleLinkedInProfileUrlTextChange = (e) => {
    setLinkedInProfileUrl(e.target.value);
  };

  const handlePersonalWebsiteOrBlogUrlTextChange = (e) => {
    setPersonalWebsiteOrBlogUrl(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    createCareerBuilderRequest({
      onResponse: (data) => {
        if (data.feedbackVariant === 'success') {
          nextStep();
        } else {
          setFeedbackMessage(data.error_message || data.message);
          setFeedbackVariant("error");
        }
      },
      onError: ({ feedbackMessage, feedbackVariant }) => {
        setFeedbackMessage(feedbackMessage);
        setFeedbackVariant(feedbackVariant);
      },
      body: {
        first_name: firstName,
        last_name: lastName,
        email: email,
        linkedin_profile_url: linkedInProfileUrl,
        personal_website_or_blog_url: personalWebsiteOrBlogUrl,
        authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      },
    });
  };

  return isActive ? (
    <React.Fragment>
      <div id={styles.xComponentContainer}>
        <img src={sharedAssetsPaths.xComponent} id={styles.xComponent} onClick={closeCareerBuilderRequestWizard}></img>
      </div>

      <section id={styles.mainContent}>
        <div id={styles.messageContainer}>
          <p id={styles.message}>
            Thanks for your interest in using Isibute.
          </p>
          <p id={styles.message}>
            Please request support in building your career by submitting your information below.
          </p>
        </div>

        <form onSubmit={onSubmit} id={styles.careerBuilderRequestForm}>
          <input
            className={styles.input}
            type="text"
            id="firstName"
            placeholder="First Name:"
            onChange={handleFirstNameTextChange}
          />

          <input
            className={styles.input}
            type="text"
            id="lastName"
            placeholder="Last Name:"
            onChange={handleLastNameTextChange}
          />

          <input
            className={styles.input}
            type="email"
            id="email"
            placeholder="Email:"
            onChange={handleEmailTextChange}
          />

          <input
            className={styles.input}
            type="url"
            id="linkedinProfileUrl"
            placeholder="LinkedIn Profile (Optional):"
            onChange={handleLinkedInProfileUrlTextChange}
          />

          <input
            className={styles.input}
            type="url"
            id="personalWebsiteOrBlog"
            placeholder="Personal Website/Blog (Optional):"
            onChange={handlePersonalWebsiteOrBlogUrlTextChange}
          />

          <input
            type="submit"
            name="commit"
            value="Build My Career"
            className={styles.careerBuilderBtn}
            data-disable-with="Build My Career"
          />
        </form>
        <div>
          <p  id={styles.nevermindCTA} onClick={closeCareerBuilderRequestWizard}>Nevermind</p>
        </div>
      </section>
    </React.Fragment>
  ) : (
    <></>
  );
};

CareerBuilderRequestForm.propTypes = {
  sharedAssetsPaths: PropTypes.shape({}).isRequired,
};

export default CareerBuilderRequestForm;
