import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import StepWizard from 'react-step-wizard';
import TeamBuilderRequestForm from './TeamBuilderRequestForm';
import TeamBuilderRequestReceived from './TeamBuilderRequestReceived';
import Feedback from '../../Feedback/components/Feedback';

import styles from './TeamBuilderRequestWizard.module.scss';

const TeamBuilderRequestWizard = ({
  sharedAssetsPaths,
  showTeamBuilderRequestWizard,
  closeTeamBuilderRequestWizard
}) => {
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [feedbackVariant, setFeedbackVariant] = useState(null);

  const clearFeedback = () => {
    setFeedbackMessage(null);
    setFeedbackVariant(null);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={showTeamBuilderRequestWizard}
        className={styles.modal}
        overlayClassName={styles.overlay}
        contentLabel="Team Builder Request Wizard"
        onRequestClose={closeTeamBuilderRequestWizard}
      >
        <StepWizard>
          <TeamBuilderRequestForm
            closeTeamBuilderRequestWizard={closeTeamBuilderRequestWizard}
            sharedAssetsPaths={sharedAssetsPaths}
            setFeedbackMessage={setFeedbackMessage}
            setFeedbackVariant={setFeedbackVariant}
          />
          <TeamBuilderRequestReceived
            closeTeamBuilderRequestWizard={closeTeamBuilderRequestWizard}
            sharedAssetsPaths={sharedAssetsPaths}
          />
        </StepWizard>
      </Modal>
      <Feedback
        message={feedbackMessage}
        variant={feedbackVariant}
        closeFeedback={clearFeedback}
      />
    </React.Fragment>
  );
};

TeamBuilderRequestWizard.propTypes = {
  sharedAssetsPaths: PropTypes.shape({}).isRequired,
};

export default TeamBuilderRequestWizard;
