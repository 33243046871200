import React from 'react';
import PropTypes from 'prop-types';

import styles from './LegalDocument.module.scss';

const LegalDocument = ({ path, documentName }) => {
  return (
    <React.Fragment>
      <iframe class={styles.iframe} width="100%" src={path} frameborder="0" allowfullscreen>
        <p>Your browser does not support iframes.</p>
        <p>{`You can find Isibute's ${documentName} at ${path}`}</p>
      </iframe>
    </React.Fragment>
  );
};

LegalDocument.propTypes = {
  path: PropTypes.string.isRequired,
  documentName: PropTypes.string.isRequired,
};

export default LegalDocument;
