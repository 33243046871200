import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Feedback from '../../Feedback/components/Feedback';

import {
  getDocuments,
  seedDocument,
} from './restApi';

import styles from './DiscourseIndex.module.scss';

const DiscourseIndex = ({ authenticatedUser }) => {
  const [state, setState] = useState({
    documents: [],
    pageNumber: 1,
    moreDocumentsAvailable: true,
    documentState: "published",
    feedbackMessage: null,
    feedbackVariant: null,
  });

  const clearFeedback = () => {
    setState(prevState => ({
      ...prevState,
      feedbackMessage: null,
      feedbackVariant: null,
    }));
  };

  const onResponse = response => setState(prevState => ({ ...prevState, ...response, documents: prevState.documents.concat(response.documents) }));

  const onError = feedback => setState(prevState => ({ ...prevState, ...feedback }));

  const discourse = () => {
    return state.documents.map(document => {
      const authorProfilePath = document?.author?.path;
      const authorName = document?.author?.name;
      const localeDateString = () => {
        const date = new Date(Date.parse(document?.publishedAt));
        return date.toLocaleDateString();
      };

      return (
        <div key={document.id} className={styles.articleContainer}>
          <div className={styles.publishingInfoContainer}>
            <p className={styles.publishingInfo}>
              Published on {localeDateString()} by <a id={styles.author} href={authorProfilePath}>{authorName}</a>
            </p>
          </div>
          <a className={styles.articleLink} href={`discourse/${document.slug}/${document.id}`} target="_blank">
            <img className={styles.img} src={document.mainImagePath} />
            <h3 className={styles.articleTitle}>{document.title}</h3>
            <p className={styles.articleSubtitle}>{document.subtitle}</p>
          </a>
        </div>
      );
    });
  };

  useEffect(() => {
    getDocuments({
      pageNumber: state.pageNumber,
      documentState: state.documentState,
      onResponse,
      onError,
    });
  }, []);

  return (
    <>
      <h3 className={styles.pageTitle}>The Latest Discourse On Isibute</h3>
      <InfiniteScroll
        dataLength={state.documents.length}
        next={() => {
          getDocuments({
            pageNumber: state.pageNumber + 1,
            documentState: state.documentState,
            onResponse,
            onError,
          });
        }}
        hasMore={state.moreDocumentsAvailable}
        loader={
          <h4 style={{ textAlign: 'center' }}>Loading...</h4>
        }
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>You've seen all available articles</b>
          </p>
        }
        scrollableTarget="html"
      >
        {discourse()}
      </InfiniteScroll>
      {authenticatedUser?.canSeedDocument && (
        <div className={styles.articleContainer}>
          <button
            className={styles.seedDocumentBtn}
            onClick={() => seedDocument({
              onResponse,
              onError,
              body: {
                authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content"),
              },
            })}
          >
            Seed Document
          </button>
        </div>
      )}
      <Feedback
        message={state.feedbackMessage}
        variant={state.feedbackVariant}
        closeFeedback={clearFeedback}
      />
    </>
  );
};

export default DiscourseIndex;
