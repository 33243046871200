import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import ReactHtmlParser from 'react-html-parser';

import { getComments } from '../../Feed/components/restApi';

import styles from './CommentsModal.module.scss';

const CommentsModal = ({ parentId, parentType, modalOpen, handleCloseModal, handleOpenCommentModal, handleOpenCommentFormModal }) => {
  const [commentData, setCommentData] = useState([]);
  const [commentDataLoaded, setCommentDataLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const comments = () => {
    return commentData.map(comment => {
      const goToAuthorProfile = () => {
        window.location.assign(comment.author.path);
      }

      const parse = (text) => {
        return ReactHtmlParser(text, {
          transform: (node) => {
            if (node.type === 'tag' && node.name === 'a') {
              return <a href={`//${node.attribs.href}`} target="_blank">{node.children[0].data}</a>;
            }
          }
        });
      };

      const handleViewReplies = () => {
        setCommentDataLoaded(false);
        handleOpenCommentModal({commentParentId: comment.id, commentParentType: 'Comment'});
      };

      return (
        <>
          <hr/>
          <div className={styles.authorIntro}>
            <img className={styles.authorPicture} src={comment.author.profilePicturePath} onClick={goToAuthorProfile}></img>
            <p class={styles.authorName}  onClick={goToAuthorProfile}>{comment.author.name}</p>
            <p className={styles.authorTagline}  onClick={goToAuthorProfile}>Biotech Enthusiast</p>
            <p className={styles.postDate}>{`Posted On ${new Date(Date.parse(comment.createdAt)).toLocaleDateString()}`}</p>
          </div>
          <div className={styles.commentTextContainer}>
            {parse(comment.text)}
          </div>
          <div className={styles.repliesContainer}>
            <p className={styles.repliesCount} onClick={comment.replies.length ? () => handleViewReplies() : () => {}}>{`${comment.replies.length} ${comment.replies.length == 1 ? 'Reply' : 'Replies'}`}</p>
            |
            <p className={styles.repliesCount} onClick={() => handleOpenCommentFormModal({commentParentId: comment.id, commentParentType: 'Comment'})}>Make A Reply</p>
          </div>
        </>
      );
    });
  };

  useEffect(() => {
    if (!commentDataLoaded) {
      getComments({
        parentId,
        parentType,
        onResponse: (data) => {
          if (data.feedbackVariant === 'success') {
            setCommentData(data.comments);
            setCommentDataLoaded(true);
          } else {
            setErrorMessage(data.message);
          }
        },
        onError: ({ feedbackMessage, feedbackVariant }) => setErrorMessage(feedbackMessage),
      });
    }
  });

  return (
    <Modal
      isOpen={modalOpen}
      className={styles.modal}
      overlayClassName={styles.overlay}
      shouldCloseOnOverlayClick={false}
      onRequestClose={handleCloseModal}
    >
      <div id={styles.createPostsContainer}>
        <button id={styles.createPostsBtn} onClick={handleCloseModal}>Close</button>
      </div>
      {comments()}
    </Modal>
  );
};

CommentsModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default CommentsModal;
