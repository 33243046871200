import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { createAndSendPasswordResetToken } from '../../Account/components/restApi';

import styles from './PasswordResetRequestForm.module.scss';

const PasswordResetRequestForm = ({ handleCloseWizard, previousStep, setFeedbackMessage, setFeedbackVariant }) => {
  const [email, setEmail] = useState(null);

  const onSubmit = () => {
    createAndSendPasswordResetToken({
      onResponse: (data) => {
        if (data.feedbackVariant === 'success') {
          setFeedbackMessage(data.message);
          setFeedbackVariant("success");
        } else {
          setFeedbackMessage(data.error_message || data.message);
          setFeedbackVariant("error");
        }
      },
      onError: ({ feedbackMessage, feedbackVariant }) => {
        setFeedbackMessage(feedbackMessage);
        setFeedbackVariant(feedbackVariant);
      },
      body: {
        email: email,
        authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content")
      },
    });
  };

  return (
    <React.Fragment>
      <div id={styles.closeModalContainer}>
        <a id={styles.closeModal} onClick={handleCloseWizard}>&#10005;</a>
      </div>
      <div id={styles.passwordResetRequestForm}>
        <label className={styles.inputLabel} htmlFor="email">Email</label>
        <input
          className={styles.input}
          type="email"
          name="account[email]"
          onChange={(e) => { setEmail(e.target.value) }}
        />

        <button className={styles.passwordResetBtn} onClick={onSubmit}>Request Password Reset</button>

        <p id={styles.backToLogin} onClick={previousStep}>Back To Login</p>
      </div>
    </React.Fragment>
  );
};

PasswordResetRequestForm.defaultProps = {
  previousStep: () => {},
};

PasswordResetRequestForm.propTypes = {
  handleCloseWizard: PropTypes.func.isRequired,
  previousStep: PropTypes.func,
};

export default PasswordResetRequestForm;
