import React, { useEffect } from 'react';

import {
  useRestrictedActionFormState,
  useRestrictedActionFormDispatch,
} from './RestrictedActionFormContext';

import styles from './RestrictedActionForm.module.scss';

const RestrictedActionForm = ({ restrictedAction, onSubmit }) => {
  const restrictedActionFormState = useRestrictedActionFormState();

  const restrictedActionFormDispatch = useRestrictedActionFormDispatch();

  const authenticityToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

  const onResponse = (response) => {
    if (response.feedbackVariant === 'success') {
      localStorage.setItem("IsibuteFeedbackMessage", response.feedbackMessage);
      localStorage.setItem("IsibuteFeedbackVariant", response.feedbackVariant);
      location.assign('/restricted_actions/');
    } else {
      restrictedActionFormDispatch({ type: 'ON_RESPONSE', response });
    }
  };

  const onError = feedback => restrictedActionFormDispatch({ type: 'ON_ERROR', feedback });

  useEffect(() => {
    if (restrictedAction) {
      restrictedActionFormDispatch({ type: 'UPDATE_FORM', restrictedAction });
    }
  }, []);

  return (
    <form id={styles.restrictedActionForm}>
      <input
        className={styles.input}
        type="text"
        id="groupId"
        placeholder="Group Id:"
        onChange={
          (e) => {
            const groupId = e.target.value;
            restrictedActionFormDispatch({ type: 'UPDATE_GROUP_ID', groupId });
          }
        }
        value={restrictedActionFormState?.restrictedActionForm?.groupId}
      />

      <input
        className={styles.input}
        type="text"
        id="restrictedActionName"
        placeholder="Restricted Action Name:"
        onChange={
          (e) => {
            const restrictedActionName = e.target.value;
            restrictedActionFormDispatch({ type: 'UPDATE_RESTRICTED_ACTION_NAME', restrictedActionName });
          }
        }
        value={restrictedActionFormState?.restrictedActionForm?.restrictedActionName}
      />

      <input
        className={styles.input}
        type="text"
        id="resourceId"
        placeholder="Resource Id:"
        onChange={
          (e) => {
            const resourceId = e.target.value;
            restrictedActionFormDispatch({ type: 'UPDATE_RESOURCE_ID', resourceId });
          }
        }
        value={restrictedActionFormState?.restrictedActionForm?.resourceId}
      />

      <input
        className={styles.input}
        type="text"
        id="resourceType"
        placeholder="Resource Type:"
        onChange={
          (e) => {
            const resourceType = e.target.value;
            restrictedActionFormDispatch({ type: 'UPDATE_RESOURCE_TYPE', resourceType });
          }
        }
        value={restrictedActionFormState?.restrictedActionForm?.resourceType}
      />

      <input
        className={styles.input}
        type="text"
        id="applyToAllUsers"
        placeholder="Apply To All Users:"
        onChange={
          (e) => {
            const applyToAllUsers = e.target.value;
            restrictedActionFormDispatch({ type: 'UPDATE_APPLY_TO_ALL_USERS', applyToAllUsers });
          }
        }
        value={restrictedActionFormState?.restrictedActionForm?.applyToAllUsers}
      />

      <button
        type="button"
        className={styles.submitBtn}
        onClick={() => {
          onSubmit({
            restrictedAction,
            onResponse,
            onError,
            body: {
              restrictedActionForm: restrictedActionFormState?.restrictedActionForm,
              authenticity_token: authenticityToken,
            },
          });
        }}
      >
        {!!restrictedAction ? 'Update Restricted Action' : 'Create Restricted Action'}
      </button>
    </form>
  );
};

export default RestrictedActionForm;
