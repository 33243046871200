import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PostModal from '../../Post/components/PostModal';
import CommentFormModal from '../../Post/components/CommentFormModal';
import CommentsModal from '../../Post/components/CommentsModal';
import Feedback from '../../Feedback/components/Feedback';
import Post from '../../Post/components/Post';

import { getPosts } from './restApi';

import styles from './Feed.module.scss';

const Feed = ({ authenticatedUser }) => {
  const [viewState, setViewState] = useState('approved');

  const [draftPostData, setDraftPostData] = useState([]);
  const [lastDraftPostPageViewed, setLastDraftPostPageViewed] = useState(0);
  const [canViewMoreDraftPosts, setCanViewMoreDraftPosts] = useState(true);

  const [pendingApprovalPostData, setPendingApprovalPostData] = useState([]);
  const [lastPendingApprovalPostPageViewed, setLastPendingApprovalPostPageViewed] = useState(0);
  const [canViewMorePendingApprovalPosts, setCanViewMorePendingApprovalPosts] = useState(true);

  const [approvedPostData, setApprovedPostData] = useState([]);
  const [lastApprovedPostPageViewed, setLastApprovedPostPageViewed] = useState(0);
  const [canViewMoreApprovedPosts, setCanViewMoreApprovedPosts] = useState(true);

  const [postModalOpen, setPostModalOpen] = useState(false);
  const [commentFormModalOpen, setCommentFormModalOpen] = useState(false);
  const [commentParentId, setCommentParentId] = useState(null);
  const [commentParentType, setCommentParentType] = useState(null);
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [feedbackVariant, setFeedbackVariant] = useState(null);

  const clearFeedback = () => {
    setFeedbackMessage(null);
    setFeedbackVariant(null);
  };

  const postData = {
    'draft' : draftPostData,
    "pending_approval": pendingApprovalPostData,
    "approved": approvedPostData,
  }[viewState];

  const setPostData = {
    "draft": setDraftPostData,
    "pending_approval": setPendingApprovalPostData,
    "approved": setApprovedPostData,
  }[viewState];

  const movePostViewState = (postToMove, targetViewState) => {
    setPostData(postData.filter((post) => post.id != postToMove.id));

    postToMove.viewState = targetViewState;

    const postDataByViewState = {
      'draft' : draftPostData,
      "pending_approval": pendingApprovalPostData,
      "approved": approvedPostData,
    };

    const setPostDataByViewState = {
      "draft": setDraftPostData,
      "pending_approval": setPendingApprovalPostData,
      "approved": setApprovedPostData,
    };

    setPostDataByViewState[targetViewState]([postToMove].concat(postDataByViewState[targetViewState]));
  }

  const lastPageViewed = {
    "draft": lastDraftPostPageViewed,
    "pending_approval": lastPendingApprovalPostPageViewed,
    "approved": lastApprovedPostPageViewed,
  }[viewState];

  const setLastPageViewed = {
    "draft": setLastDraftPostPageViewed,
    "pending_approval": setLastPendingApprovalPostPageViewed,
    "approved": setLastApprovedPostPageViewed,
  }[viewState];

  const canViewMorePosts = {
    "draft": canViewMoreDraftPosts,
    "pending_approval": canViewMorePendingApprovalPosts,
    "approved": canViewMoreApprovedPosts,
  }[viewState];

  const setCanViewMorePosts = {
    "draft": setCanViewMoreDraftPosts,
    "pending_approval": setCanViewMorePendingApprovalPosts,
    "approved": setCanViewMoreApprovedPosts,
  }[viewState];

  const openPostModal = (e) => {
    e.preventDefault();
    setPostModalOpen(true);
  };

  const handleClosePostModal = () => {
    setPostModalOpen(false);
  };

  const openCommentFormModal = ({commentParentId, commentParentType}) => {
    setCommentModalOpen(false);
    setCommentFormModalOpen(true);
    setCommentParentId(commentParentId);
    setCommentParentType(commentParentType);
  };

  const handleCloseCommentFormModal = () => {
    setCommentFormModalOpen(false);
  };

  const openCommentModal = ({commentParentId, commentParentType}) => {
    setCommentFormModalOpen(false);
    setCommentModalOpen(true);
    setCommentParentId(commentParentId);
    setCommentParentType(commentParentType);
  };

  const handleCloseCommentModal = () => {
    setCommentModalOpen(false);
  };

  const posts = () => {
    return postData.map(post => {
      return (
        <Post
          key={post.id}
          post={post}
          handleOpenCommentFormModal={openCommentFormModal}
          handleOpenCommentModal={openCommentModal}
          setFeedbackMessage={setFeedbackMessage}
          setFeedbackVariant={setFeedbackVariant}
          moveViewState={movePostViewState}
        />
      );
    });
  };

  const viewStateButtonStyle = (viewStateButtonName) => {
    const activeStatusClass = (viewState == viewStateButtonName) ? styles.active : styles.inactive;
    return `${styles.postStateButton} ${activeStatusClass}`;
  };

  useEffect(() => {
    if (postData.length == 0) {
      getPosts({
        lastPageViewed,
        viewState,
        onResponse: (data) => {
          if (data.feedbackVariant === 'success') {
            setPostData(postData.concat(data.posts));
            setLastPageViewed(data.pageNumber);
            setCanViewMorePosts(data.canViewMorePosts);
          } else {
            setErrorMessage(data.message);
          }
        },
        onError: ({ feedbackMessage, feedbackVariant }) => setErrorMessage(feedbackMessage),
      });
    }
  }, [viewState]);

  return (
    <React.Fragment>
      <div id={styles.infinitScrollContainer}>
        <InfiniteScroll
          dataLength={postData.length}
          next={() => {
            getPosts({
              lastPageViewed,
              viewState,
              onResponse: (data) => {
                if (data.feedbackVariant === 'success') {
                  setPostData(postData.concat(data.posts));
                  setLastPageViewed(data.pageNumber);
                  setCanViewMorePosts(data.canViewMorePosts);
                } else {
                  setErrorMessage(data.message);
                }
              },
              onError: ({ feedbackMessage, feedbackVariant }) => setErrorMessage(feedbackMessage),
            });
          }}
          hasMore={canViewMorePosts}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>You've seen all the posts</b>
            </p>
          }
          scrollableTarget="html"
        >
          <div id={styles.createPostsContainer}>
            {!!authenticatedUser && authenticatedUser.canAdministratePosts && <button class={viewStateButtonStyle('draft')} onClick={() => { setViewState('draft') }}>Drafts</button>}
            {!!authenticatedUser && authenticatedUser.canAdministratePosts  && <button class={viewStateButtonStyle('pending_approval')} onClick={() => { setViewState('pending_approval') }}>In Review</button>}
            {!!authenticatedUser && authenticatedUser.canAdministratePosts  && <button class={viewStateButtonStyle('approved')} onClick={() => { setViewState('approved') }}>Published</button>}
            <button id={styles.createPostsBtn} onClick={openPostModal}>Make a post</button>
          </div>
          {posts()}
        </InfiniteScroll>
      </div>
      <PostModal
        modalOpen={postModalOpen}
        handleCloseWizard={handleClosePostModal}
      />
      <CommentFormModal
        modalOpen={commentFormModalOpen}
        handleCloseModal={handleCloseCommentFormModal}
        parentId={commentParentId}
        parentType={commentParentType}
      />
      {commentModalOpen && (
        <CommentsModal
          modalOpen={commentModalOpen}
          handleCloseModal={handleCloseCommentModal}
          parentId={commentParentId}
          parentType={commentParentType}
          handleOpenCommentFormModal={openCommentFormModal}
          handleOpenCommentModal={openCommentModal}
        />
      )}
      <Feedback
        message={feedbackMessage}
        variant={feedbackVariant}
        closeFeedback={clearFeedback}
      />
    </React.Fragment>
  );
};

export default Feed;
