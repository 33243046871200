import { HTTPRequestMethods } from "../../Shared/httpRequestMethods";

export const getUser = ({ user, onResponse, onError }) => {
  fetch(
    `/api/users/${user.id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const getUsers = ({ onResponse, onError }) => {
  fetch(
    "/api/users",
    {
      method: HTTPRequestMethods.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const updateCurrentUserBiography = ({ onResponse, onError, body }) => {
  fetch(
    "/api/users/update_biography",
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const updateCurrentUserName = ({ onResponse, onError, body }) => {
  fetch(
    "/api/users/update_name",
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const updateCurrentUserProfilePicture = ({ onResponse, onError, body }) => {
  fetch(
    "/api/users/update_profile_picture",
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};