import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { updateCurrentUserProfilePicture } from './restApi';

import styles from './UserProfilePictureUpdateForm.module.scss';

const UserProfilePictureUpdateForm = ({
  handleCloseUserProfilePictureUpdateModal,
  handleUserProfilePictureUpdate,
  initialUserProfilePicturePath,
}) => {
  const [profilePicturePath, setProfilePicturePath] = useState(initialUserProfilePicturePath);
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePictureFileName, setProfilePicutureFileName] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const base64Image = (image) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result
        .replace("data", "")
        .replace(/^.+,/, "");

      setProfilePicture(base64String);
      setProfilePicutureFileName(image.name);
    };

    reader.readAsDataURL(image);
  }

  const onSubmit = (e) => {
    e.preventDefault();

    updateCurrentUserProfilePicture({
      onResponse: (data) => {
        if (data.feedbackVariant === 'success') {
          setProfilePicturePath(data.user.profilePicturePath);
          handleUserProfilePictureUpdate(data.user.profilePicturePath);
          handleCloseUserProfilePictureUpdateModal();
        } else {
          setErrorMessage(data.error_message);
        }
      },
      onError: ({ feedbackMessage, feedbackVariant }) => { setErrorMessage(feedbackMessage) },
      body: {
        profile_picture: profilePicture,
        profile_picture_file_name: profilePictureFileName,
        authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content")
      },
    });
  };

  return (
    <React.Fragment>
      { errorMessage && <h3 className="error"> { errorMessage } </h3> }

      <section id={styles.profilePictureSection}>
        <img id={styles.profilePicture} src={profilePicturePath}></img>
      </section>

      <form onSubmit={onSubmit} id={styles.loginForm}>
        <label className={styles.inputLabel}>Image</label>
        <input
          className={styles.input}
          type="file"
          accept="image/png,image/gif,image/jpeg"
          onChange={(e) => { base64Image(e.target.files[0]) }}
        />

        <input
          type="submit"
          name="commit"
          value="Update Profile Picture"
          className={styles.loginBtn}
          data-disable-with="Update Profile Picture"
        />
      </form>
    </React.Fragment>
  );
};

UserProfilePictureUpdateForm.propTypes = {
  handleCloseUserProfilePictureUpdateModal: PropTypes.func.isRequired,
  handleUserProfilePictureUpdate: PropTypes.func.isRequired,
  initialUserProfilePicturePath: PropTypes.string,
};

export default UserProfilePictureUpdateForm;
