import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import EmailUpdateForm from './EmailUpdateForm';
import PasswordUpdateForm from './PasswordUpdateForm';
import CloseAccountForm from './CloseAccountForm';

import { getCurrentUserAccount } from './restApi';

import styles from './Account.module.scss';

const Account = () => {
  const [primaryEmail, setPrimaryEmail] = useState(null);
  const [emailUpdateModalOpen, setEmailUpdateModalOpen] = useState(false);
  const [passwordUpdateModalOpen, setPasswordUpdateModalOpen] = useState(false);
  const [accountCloseModalOpen, setAccountCloseModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleCloseEmailUpdateModal = () => {
    setEmailUpdateModalOpen(false);
  };

  const handleOpenEmailUpdateModal = () => {
    setEmailUpdateModalOpen(true);
  };

  const handleEmailUpdate = (email) => {
    setPrimaryEmail(email);
  };

  const handleClosePasswordUpdateModal = () => {
    setPasswordUpdateModalOpen(false)
  };

  const handleOpenPasswordUpdateModal = () => {
    setPasswordUpdateModalOpen(true);
  };

  const handleCloseAccountCloseModal = () => {
    setAccountCloseModalOpen(false)
  };

  const handleOpenAccountCloseModal = () => {
    setAccountCloseModalOpen(true);
  };

  useEffect(() => {
    Modal.setAppElement('body');

    getCurrentUserAccount({
      onResponse: (data) => setPrimaryEmail(data.account.primaryEmail),
      onError: ({ feedbackMessage, feedbackVariant }) => {},
    });
  });

  return (
    <React.Fragment>
      <section className={styles.accountPageSection}>
        <h1>Manage Account</h1>
        <hr/>
        <h2 className={styles.subtitle}>Email Address</h2>
        <p className={styles.cta} onClick={handleOpenEmailUpdateModal}>Update</p>
        <p className={styles.currentValue}>{primaryEmail}</p>
        <p className={styles.description}>The email address associated with your Isibute account.</p>
        <hr/>
        <h2 className={styles.subtitle}>Password</h2>
        <p className={styles.cta} onClick={handleOpenPasswordUpdateModal}>Update</p>
        <p className={styles.currentValue}>***...</p>
        <p className={styles.description}>Your password for your Isibute account. For security, do not reuse on other websites.</p>
        <hr/>
        <h2 className={styles.subtitle}>Close Account</h2>
        <p className={styles.closeCta} onClick={handleOpenAccountCloseModal}>Close Permanently</p>
        <p className={styles.description}>Once you close your Isibute account, it cannot be reopened.</p>
      </section>
      <Modal
        isOpen={emailUpdateModalOpen}
        className={styles.modal}
        overlayClassName={styles.overlay}
        contentLabel="Update Email"
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleCloseEmailUpdateModal}
      >
        <EmailUpdateForm
          handleCloseEmailUpdateModal={handleCloseEmailUpdateModal}
          handleEmailUpdate={handleEmailUpdate}
        />
      </Modal>
      <Modal
        isOpen={passwordUpdateModalOpen}
        className={styles.modal}
        overlayClassName={styles.overlay}
        contentLabel="Update Password"
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleClosePasswordUpdateModal}
      >
        <PasswordUpdateForm
          handleClosePasswordUpdateModal={handleClosePasswordUpdateModal}
        />
      </Modal>
      <Modal
        isOpen={accountCloseModalOpen}
        className={styles.modal}
        overlayClassName={styles.closeOverlay}
        contentLabel="Close Account"
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleCloseAccountCloseModal}
      >
        <CloseAccountForm />
      </Modal>
    </React.Fragment>
  );
};

export default Account;
