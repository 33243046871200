import React, { useEffect, useState } from "react";

import Feedback from "../../Feedback/components/Feedback";

import {
  getRole,
  addRestrictedActionToRole,
  removeRestrictedActionFromRole,
  addUserToRole,
  removeUserFromRole,
} from './restApi';

import styles from './Show.module.scss';

const Show = ({ role }) => {
  const [state, setState] = useState({
    role: null,
    newRestrictedAction: {
      id: '',
    },
    newUser: {
      id: '',
    },
    feedbackMessage: null,
    feedbackVariant: null,
  });

  const onResponse = response => setState(prevState => ({ ...prevState, ...response }));

  const onError = feedback => setState(prevState => ({ ...prevState, ...feedback }));

  const closeFeedback = () => setState(prevState => ({ ...prevState, feedbackMessage: null, feedbackVariant: null }));

  const authenticityToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

  const goToRolesIndex = () => {
    location.assign(`/roles/`);
  };

  const goToRoleEdit = (role) => {
    location.assign(`/roles/${role.id}/edit`);
  };

  const goToRestrictedAction = (restrictedAction) => {
    location.assign(`/restricted_actions/${restrictedAction.id}`);
  };

  const goToUser = (user) => {
    location.assign(`/users/${user.id}`);
  };

  useEffect(() => {
    getRole({ role, onResponse, onError });
  }, []);

  return (
    <>
      <Feedback
        message={state?.feedbackMessage}
        variant={state?.feedbackVariant}
        closeFeedback={closeFeedback}
      />
      <section>
        <button
          className={styles.viewAllRolesBtn}
          onClick={() => goToRolesIndex()}
        >
          View All Roles
        </button>
      </section>
      <section>
        <h2 className={styles.tableTitle}>
          Role
        </h2>
        {state?.role && (
          <table>
            <thead>
              <tr>
                <th>Group Id</th>
                <th>Role Name</th>
              </tr>
            </thead>
            <tbody>
              <tr key={state?.role.id}>
                <td>{state?.role.groupId}</td>
                <td>{state?.role.roleName}</td>
                <td>
                  <button
                    className={styles.viewDetailsBtn}
                    onClick={() => goToRoleEdit(role)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </section>
      <section>
        <h2 className={styles.tableTitle}>
          Roles
        </h2>
        <form  id={styles.addRestrictedActionForm}>
          <input
            className={styles.input}
            type="text"
            id="restrictedActionId"
            placeholder="Restricted Action Id:"
            onChange={
              (e) => {
                const restrictedActionId = e.target.value;
                const newRestrictedAction = { id: restrictedActionId };
                setState(prevState => ({ ...prevState, newRestrictedAction }))
              }
            }
            value={state?.newRestrictedAction?.id}
          />
          <button
            type="button"
            className={styles.addRestrictedActionBtn}
            onClick={() => {
              addRestrictedActionToRole({
                role,
                onResponse,
                onError,
                body: {
                  newRestrictedAction: state?.newRestrictedAction,
                  authenticity_token: authenticityToken,
                },
              });
            }}
          >
            Add Restricted Action
          </button>
        </form>
        {state?.role?.restrictedActions?.length ? (
          <table>
            <thead>
              <tr>
                <th>Group Id</th>
                <th>Restricted Action Name</th>
                <th>Resource Id</th>
                <th>Resource Type</th>
                <th>Apply To All Users</th>
              </tr>
            </thead>
            <tbody>
              {state?.role?.restrictedActions?.map(restrictedAction => {
                return (
                  <tr key={restrictedAction.id}>
                    <td>{restrictedAction.groupId}</td>
                    <td>{restrictedAction.restrictedActionName}</td>
                    <td>{restrictedAction.resourceId}</td>
                    <td>{restrictedAction.resourceType}</td>
                    <td>{restrictedAction.applyToAllUsers.toString()}</td>
                    <td>
                      <button
                        className={styles.viewDetailsBtn}
                        onClick={() => goToRestrictedAction(restrictedAction)}
                      >
                        View Details
                      </button>
                    </td>
                    <td>
                      <button
                        className={styles.removeRestrictedActionBtn}
                        onClick={() => {
                          removeRestrictedActionFromRole({
                            role,
                            onResponse,
                            onError,
                            body: {
                              restrictedAction,
                              authenticity_token: authenticityToken,
                            },
                          });
                        }}
                      >
                        Remove Restricted Action
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className={styles.noContentMessage}>No restricted actions for this role.</p>
        )}
      </section>
      <section>
        <h2 className={styles.tableTitle}>
          Users
        </h2>
        <form  id={styles.addUserForm}>
          <input
            className={styles.input}
            type="text"
            id="userId"
            placeholder="User Id:"
            onChange={
              (e) => {
                const userId = e.target.value;
                const newUser = { id: userId };
                setState(prevState => ({ ...prevState, newUser }))
              }
            }
            value={state?.newUser?.id}
          />
          <button
            type="button"
            className={styles.addUserBtn}
            onClick={() => {
              addUserToRole({
                role,
                onResponse,
                onError,
                body: {
                  newUser: state?.newUser,
                  authenticity_token: authenticityToken,
                },
              });
            }}
          >
            Add User
          </button>
        </form>
        {state?.role?.users?.length ? (
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
              </tr>
            </thead>
            <tbody>
              {state?.role?.users?.map(user => {
                return (
                  <tr key={user.id}>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>
                      <button
                        className={styles.viewDetailsBtn}
                        onClick={() => goToUser(user)}
                      >
                        View Details
                      </button>
                    </td>
                    <td>
                      <button
                        className={styles.removeUserBtn}
                        onClick={() => {
                          removeUserFromRole({
                            role,
                            onResponse,
                            onError,
                            body: {
                              user,
                              authenticity_token: authenticityToken,
                            },
                          });
                        }}
                      >
                        Remove User
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className={styles.noContentMessage}>No users with this role.</p>
        )}
      </section>
    </>
  );
};

export default Show;
