import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import UserNameUpdateForm from './UserNameUpdateForm';
import UserIntroductionUpdateForm from './UserIntroductionUpdateForm';
import UserProfilePictureUpdateForm from './UserProfilePictureUpdateForm';
import PropTypes from 'prop-types';

import { getUser } from './restApi';

import styles from './UserShow.module.scss';

const UserShow = ({ user }) => {
  const [userProfilePicturePath, setUserProfilePicturePath] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  const [userIntroduction, setUserIntroduction] = useState(null);
  const [userIsCurrentUser, setUserIsCurrentUser] = useState(false);
  const [userProfilePictureUpdateModalOpen, setUserProfilePictureUpdateModalOpen] = useState(false);
  const [userNameUpdateModalOpen, setUserNameUpdateModalOpen] = useState(false);
  const [userIntroductionUpdateModalOpen, setUserIntroductionUpdateModalOpen] = useState(false);
  const [userIsABot, setUserIsABot] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleCloseUserProfilePictureUpdateModal = () => {
    setUserProfilePictureUpdateModalOpen(false);
  };

  const handleOpenUserProfilePictureUpdateModal = () => {
    setUserProfilePictureUpdateModalOpen(true);
  };

  const handleUserProfilePictureUpdate = (path) => {
    setUserProfilePicturePath(path);
  };

  const handleCloseUserNameUpdateModal = () => {
    setUserNameUpdateModalOpen(false);
  };

  const handleOpenUserNameUpdateModal = () => {
    setUserNameUpdateModalOpen(true);
  };

  const handleUserFirstNameUpdate = (firstName) => {
    setUserFirstName(firstName);
  };

  const handleUserLastNameUpdate = (lastName) => {
    setUserLastName(lastName);
  };

  const handleCloseUserIntroductionUpdateModal = () => {
    setUserIntroductionUpdateModalOpen(false);
  };

  const handleOpenUserIntroductionUpdateModal = () => {
    setUserIntroductionUpdateModalOpen(true);
  };

  const handleUserIntroductionUpdate = (introduction) => {
    setUserIntroduction(introduction);
  };

  useEffect(() => {
    getUser({
      user,
      onResponse: (data) => {
        if (data.feedbackVariant === 'success') {
          setUserProfilePicturePath(data.user.profilePicturePath);
          setUserFirstName(data.user.firstName);
          setUserLastName(data.user.lastName);
          setUserIntroduction(data.user.biography);
          setUserIsCurrentUser(data.user.isAuthenticatedUser);
          setUserIsABot(data.user.isABot);
        } else {
          setErrorMessage(data.error_message);
        }
      },
      onError: ({ feedbackMessage, feedbackVariant }) => { setErrorMessage(feedbackMessage) },
    });
  }, []);

  return (
    <React.Fragment>
      <section id={styles.profilePictureSection}>
        <img id={styles.profilePicture} src={userProfilePicturePath} onClick={userIsCurrentUser ? handleOpenUserProfilePictureUpdateModal : null}></img>
      </section>
      <section id={styles.userNameSection}>
        <h1 id={styles.userName}>{userIsABot ? userFirstName : `${userFirstName} ${userLastName}`} {userIsCurrentUser && (<a id={styles.editUserNameLink} onClick={handleOpenUserNameUpdateModal}>edit</a>)}</h1>
      </section>
      {userIntroduction && (
        <section id={styles.userIntroductionSection}>
          <div>
            <h2>
              Introduction
            </h2>
            <p>
              {userIntroduction} {userIsCurrentUser && (<a id={styles.editIntroductionLink} onClick={handleOpenUserIntroductionUpdateModal}>edit</a>)}
            </p>
          </div>
        </section>
      )}
      {!userIntroduction && userIsCurrentUser && (
        <section id={styles.userIntroductionSection}>
          <div>
            <h2 className={styles.visibleOnlyToYou}>
              Introduction
            </h2>
            <p className={styles.visibleOnlyToYou}>
              This section is currently only visible to you since you do not currently have an introduction to display. <a id={styles.editIntroductionLink} onClick={handleOpenUserIntroductionUpdateModal}>edit</a>
            </p>
          </div>
        </section>
      )}
      <Modal
        isOpen={userProfilePictureUpdateModalOpen}
        className={styles.modal}
        overlayClassName={styles.overlay}
        contentLabel="Update Profile Picture"
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleCloseUserProfilePictureUpdateModal}
      >
        <UserProfilePictureUpdateForm
          handleCloseUserProfilePictureUpdateModal={handleCloseUserProfilePictureUpdateModal}
          handleUserProfilePictureUpdate={handleUserProfilePictureUpdate}
          initialUserProfilePicturePath={userProfilePicturePath}
        />
      </Modal>
      <Modal
        isOpen={userNameUpdateModalOpen}
        className={styles.modal}
        overlayClassName={styles.overlay}
        contentLabel="Update User Name"
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleCloseUserNameUpdateModal}
      >
        <UserNameUpdateForm
          handleCloseUserNameUpdateModal={handleCloseUserNameUpdateModal}
          handleUserFirstNameUpdate={handleUserFirstNameUpdate}
          handleUserLastNameUpdate={handleUserLastNameUpdate}
          initialFirstName={userFirstName}
          initialLastName={userLastName}
        />
      </Modal>
      <Modal
        isOpen={userIntroductionUpdateModalOpen}
        className={styles.modal}
        overlayClassName={styles.overlay}
        contentLabel="Update Introduction"
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleCloseUserIntroductionUpdateModal}
      >
        <UserIntroductionUpdateForm
          handleCloseUserIntroductionUpdateModal={handleCloseUserIntroductionUpdateModal}
          handleUserIntroductionUpdate={handleUserIntroductionUpdate}
          initialIntroduction={userIntroduction}
        />
      </Modal>
    </React.Fragment>
  );
};

UserShow.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default UserShow;
