import React, { useEffect, useState } from "react";

import Feedback from "../../Feedback/components/Feedback";

import {
  getRestrictedAction,
  addRoleToRestrictedAction,
  removeRoleFromRestrictedAction,
  addUserToRestrictedAction,
  removeUserFromRestrictedAction,
} from './restApi';

import styles from './Show.module.scss';

const Show = ({ restrictedAction }) => {
  const [state, setState] = useState({
    restrictedAction: null,
    newRole: {
      id: '',
    },
    newUser: {
      id: '',
    },
    feedbackMessage: null,
    feedbackVariant: null,
  });

  const onResponse = response => setState(prevState => ({ ...prevState, ...response }));

  const onError = feedback => setState(prevState => ({ ...prevState, ...feedback }));

  const closeFeedback = () => setState(prevState => ({ ...prevState, feedbackMessage: null, feedbackVariant: null }));

  const authenticityToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

  const goToRestrictedActionsIndex = () => {
    location.assign(`/restricted_actions/`);
  };

  const goToRestrictedActionEdit = (restrictedAction) => {
    location.assign(`/restricted_actions/${restrictedAction.id}/edit`);
  };

  const goToRole = (role) => {
    location.assign(`/roles/${role.id}`);
  };

  const goToUser = (user) => {
    location.assign(`/users/${user.id}`);
  };

  useEffect(() => {
    getRestrictedAction({ restrictedAction, onResponse, onError });
  }, []);

  return (
    <>
      <Feedback
        message={state?.feedbackMessage}
        variant={state?.feedbackVariant}
        closeFeedback={closeFeedback}
      />
      <section>
        <button
          className={styles.viewAllRestrictedActionsBtn}
          onClick={() => goToRestrictedActionsIndex()}
        >
          View All Restricted Actions
        </button>
      </section>
      <section>
        <h2 className={styles.tableTitle}>
          Restricted Action
        </h2>
        {state?.restrictedAction && (
          <table>
            <thead>
              <tr>
                <th>Group Id</th>
                <th>Restricted Action Name</th>
                <th>Resource Id</th>
                <th>Resource Type</th>
                <th>Apply To All Users</th>
              </tr>
            </thead>
            <tbody>
              <tr key={state?.restrictedAction.id}>
                <td>{state?.restrictedAction.groupId}</td>
                <td>{state?.restrictedAction.restrictedActionName}</td>
                <td>{state?.restrictedAction.resourceId}</td>
                <td>{state?.restrictedAction.resourceType}</td>
                <td>{state?.restrictedAction.applyToAllUsers.toString()}</td>
                <td>
                  <button
                    className={styles.viewDetailsBtn}
                    onClick={() => goToRestrictedActionEdit(restrictedAction)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </section>
      <section>
        <h2 className={styles.tableTitle}>
          Roles
        </h2>
        <form  id={styles.addRoleForm}>
          <input
            className={styles.input}
            type="text"
            id="roleId"
            placeholder="Role Id:"
            onChange={
              (e) => {
                const roleId = e.target.value;
                const newRole = { id: roleId };
                setState(prevState => ({ ...prevState, newRole }))
              }
            }
            value={state?.newRole?.id}
          />
          <button
            type="button"
            className={styles.addRoleBtn}
            onClick={() => {
              addRoleToRestrictedAction({
                restrictedAction,
                onResponse,
                onError,
                body: {
                  newRole: state?.newRole,
                  authenticity_token: authenticityToken,
                },
              });
            }}
          >
            Add Role
          </button>
        </form>
        {state?.restrictedAction?.roles?.length ? (
          <table>
            <thead>
              <tr>
                <th>Group Id</th>
                <th>Role Name</th>
              </tr>
            </thead>
            <tbody>
              {state?.restrictedAction?.roles?.map(role => {
                return (
                  <tr key={role.id}>
                    <td>{role.groupId}</td>
                    <td>{role.roleName}</td>
                    <td>
                      <button
                        className={styles.viewDetailsBtn}
                        onClick={() => goToRole(role)}
                      >
                        View Details
                      </button>
                    </td>
                    <td>
                      <button
                        className={styles.removeRoleBtn}
                        onClick={() => {
                          removeRoleFromRestrictedAction({
                            restrictedAction,
                            onResponse,
                            onError,
                            body: {
                              role,
                              authenticity_token: authenticityToken,
                            },
                          });
                        }}
                      >
                        Remove Role
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className={styles.noContentMessage}>No roles for this restricted action.</p>
        )}
      </section>
      <section>
        <h2 className={styles.tableTitle}>
          Users
        </h2>
        <form  id={styles.addUserForm}>
          <input
            className={styles.input}
            type="text"
            id="userId"
            placeholder="User Id:"
            onChange={
              (e) => {
                const userId = e.target.value;
                const newUser = { id: userId };
                setState(prevState => ({ ...prevState, newUser }))
              }
            }
            value={state?.newUser?.id}
          />
          <button
            type="button"
            className={styles.addUserBtn}
            onClick={() => {
              addUserToRestrictedAction({
                restrictedAction,
                onResponse,
                onError,
                body: {
                  newUser: state?.newUser,
                  authenticity_token: authenticityToken,
                },
              });
            }}
          >
            Add User
          </button>
        </form>
        {state?.restrictedAction?.users?.length ? (
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
              </tr>
            </thead>
            <tbody>
              {state?.restrictedAction?.users?.map(user => {
                return (
                  <tr key={user.id}>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>
                      <button
                        className={styles.viewDetailsBtn}
                        onClick={() => goToUser(user)}
                      >
                        View Details
                      </button>
                    </td>
                    <td>
                      <button
                        className={styles.removeUserBtn}
                        onClick={() => {
                          removeUserFromRestrictedAction({
                            restrictedAction,
                            onResponse,
                            onError,
                            body: {
                              user,
                              authenticity_token: authenticityToken,
                            },
                          });
                        }}
                      >
                        Remove User
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className={styles.noContentMessage}>No users with this restricted action.</p>
        )}
      </section>
    </>
  );
};

export default Show;
