import React, { useState } from 'react';

import { closeCurrentUserAccount } from './restApi';

import styles from './CloseAccountForm.module.scss';

const CloseAccountForm = () => {
  const [password, setPassword] = useState(null);
  const [explicitAcknowledgementPhrase, setExplicitAcknowledgementPhrase] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handlePasswordTextChange = (e) => {
    setPassword(e.target.value);
  };

  const handleExplicitAcknowledgementPhraseTextChange = (e) => {
    setExplicitAcknowledgementPhrase(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    closeCurrentUserAccount({
      onResponse: (data) => {
        if (data.feedbackVariant === 'success') {
          window.location.assign("/");
        } else {
          setErrorMessage(data.error_message);
        }
      },
      onError: ({ feedbackMessage, feedbackVariant }) => setErrorMessage(feedbackMessage),
      body: {
        password: password,
        explicit_acknowledgement_phrase: explicitAcknowledgementPhrase,
        authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content")
      },
    })
  };

  return (
    <React.Fragment>
      { errorMessage && <h3 className="error"> { errorMessage } </h3> }

      <form onSubmit={onSubmit} id={styles.loginForm}>
        <label className={styles.inputLabel} htmlFor="explicit_acknowledgement_phrase">Enter phrase: "Close my account. This can not be undone."</label>
        <input
          className={styles.input}
          id="explicit_acknowledgement_phrase"
          onChange={handleExplicitAcknowledgementPhraseTextChange}
        />

        <label className={styles.inputLabel} htmlFor="password">Password</label>
        <input
          className={styles.input}
          type="password"
          name="session[password]"
          id="password"
          onChange={handlePasswordTextChange}
        />

        <input type="submit" name="commit" value="Close Account Permanently" className={styles.loginBtn} data-disable-with="Close Account Permanently" />
      </form>
    </React.Fragment>
  );
};

export default CloseAccountForm;
