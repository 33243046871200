import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { updateCurrentUserName } from './restApi';

import styles from './UserNameUpdateForm.module.scss';

const UserNameUpdateForm = ({ initialFirstName, initialLastName, handleUserFirstNameUpdate, handleUserLastNameUpdate, handleCloseUserNameUpdateModal }) => {
  const [firstName, setFirstName] = useState(initialFirstName);
  const [lastName, setLastName] = useState(initialLastName);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleFirstNameTextChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameTextChange = (e) => {
    setLastName(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    updateCurrentUserName({
      onResponse: (data) => {
        if (data.feedbackVariant === 'success') {
          handleUserFirstNameUpdate(firstName);
          handleUserLastNameUpdate(lastName);
          handleCloseUserNameUpdateModal();
        } else {
          setErrorMessage(data.error_message);
        }
      },
      onError: ({ feedbackMessage, feedbackVariant }) => { setErrorMessage(feedbackMessage) },
      body: {
        first_name: firstName,
        last_name: lastName,
        authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content")
      },
    });
  };

  return (
    <React.Fragment>
      { errorMessage && <h3 className="error"> { errorMessage } </h3> }

      <form onSubmit={onSubmit} id={styles.loginForm}>
        <label className={styles.inputLabel}>First Name</label>
        <input
          className={styles.input}
          id="firstName"
          onChange={handleFirstNameTextChange}
          value={firstName}
        />

        <label className={styles.inputLabel}>Last Name</label>
        <input
          className={styles.input}
          id="lastName"
          onChange={handleLastNameTextChange}
          value={lastName}
        />

        <input type="submit" name="commit" value="Update Name" className={styles.loginBtn} data-disable-with="Update Name" />
      </form>
    </React.Fragment>
  );
};

UserNameUpdateForm.propTypes = {
  handleCloseUserNameUpdateModal: PropTypes.func.isRequired,
  handleUserFirstNameUpdate: PropTypes.func.isRequired,
  handleUserLastNameUpdate: PropTypes.func.isRequired,
  initialFirstName: PropTypes.string,
  initialLastName: PropTypes.string,
};

export default UserNameUpdateForm;
