import React from 'react';

import { AppStateProvider } from './StateContext';
import { appReducer } from './AppReducer';

import AppFeedback from '../AppFeedback/components/AppFeedback';
import Header from '../Header/components/Header';
import Footer from '../Footer/components/Footer';

export default function rootComponentWrapper(Component) {
  const App = (props) => {
    return (
      <div>
        <div id="appContainer">
          <AppStateProvider
            reducer={appReducer}
          >
            <AppFeedback />
            <Header { ...props } />
            <main>
              <Component { ...props } />
            </main>
            <Footer { ...props } />
          </AppStateProvider>
        </div>
      </div>
    );
  };

  return App;
};
