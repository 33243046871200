import React, { useState } from 'react';
import styles from './LandingPage.module.scss';

import TeamBuilderRequestWizard from '../../TeamBuilderRequestWizard/components/TeamBuilderRequestWizard';
import CareerBuilderRequestWizard from '../../CareerBuilderRequestWizard/components/CareerBuilderRequestWizard';

const LandingPage = ({ sharedAssetsPaths }) => {
  const [showTeamBuilderRequestWizard, setShowTeamBuilderRequestWizard] = useState(false);
  const [showCareerBuilderRequestWizard, setShowCareerBuilderRequestWizard] = useState(false);

  const buildYourTeamMessage = `
    It's essential that your company attract and retain the right talent
    in an appropriate amount of time and budget. We start
    with a consultation to get a better understanding of your company, its
    goals, and the ideal candidate. Then we agree on the right next steps.
    This could range from getting Isibute's guidance so you can conduct
    the a more efficient search on your own, to kicking off a contingent
    search, or retaining Isibute's services for your business critical
    talent searches.`;

  const buildYourCareerMessage = `
    Biotech is a dynamic sector that doesn't come with a manual.
    When you're looking to make a move in your career it's easy
    to get buried in a lonely slog of gruntwork and guesswork.
    But it doesn't have to be that way. We'll give you personalized
    career guidance that helps you understand the market of biotech
    career opportunities and make more informed decisions based on
    your particular skillset, interests, and long term goals.`;

  return (
    <React.Fragment>
      <section id={styles.splash} style={{backgroundImage: `url(${sharedAssetsPaths.ecosystem})`}}>
        <p id={styles.introduction}>Helping biotechs build teams by helping talent build careers.</p>
        <div id={styles.splashCTAContainer}>
          <button id={styles.buildYourTeamCTABtn} onClick={() => { setShowTeamBuilderRequestWizard(true) }}>Build Your Team</button>
          <button id={styles.buildYourCareerCTABtn} onClick={() => { setShowCareerBuilderRequestWizard(true) }}>Build Your Career</button>
        </div>
      </section>
      <section id={styles.featuresContainer}>
        <section id={styles.buildYourTeam} className={styles.featureSection}>
          <h4 className={styles.mobileFeatureTitle}>Build Your Team</h4>
          <div id={styles.buildYourTeamFeatureContainer} className={styles.featureContainer}>
            <h4 className={styles.featureTitle}>Build Your Team</h4>
            <p className={styles.featureDescription}>{buildYourTeamMessage}</p>
            <button className={styles.invitationCTABtn} onClick={() => { setShowTeamBuilderRequestWizard(true) }}>Let's Connect</button>
          </div>
          <img id={styles.buildYourTeamImage} className={styles.image} src={sharedAssetsPaths.biotechCommunity}></img>
        </section>
        <section id={styles.buildYourCareer} className={styles.featureSection}>
          <h4 className={styles.mobileFeatureTitle}>Build Your Career</h4>
          <div id={styles.buildYourCareerFeatureContainer} className={styles.featureContainer}>
            <h4 className={styles.featureTitle}>Build Your Career</h4>
            <p className={styles.featureDescription}>{buildYourCareerMessage}</p>
            <button className={styles.invitationCTABtn} onClick={() => { setShowCareerBuilderRequestWizard(true) }}>Let's Connect</button>
          </div>
          <img id={styles.buildYourCareerImage} className={styles.image} src={sharedAssetsPaths.biotechJob}></img>
        </section>
      </section>

      <TeamBuilderRequestWizard
        sharedAssetsPaths={sharedAssetsPaths}
        showTeamBuilderRequestWizard={showTeamBuilderRequestWizard}
        closeTeamBuilderRequestWizard={() => { setShowTeamBuilderRequestWizard(false)} }
      />
      <CareerBuilderRequestWizard
        sharedAssetsPaths={sharedAssetsPaths}
        showCareerBuilderRequestWizard={showCareerBuilderRequestWizard}
        closeCareerBuilderRequestWizard={() => { setShowCareerBuilderRequestWizard(false)} }
      />
    </React.Fragment>
  );
};

export default LandingPage;
