import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { createEvent } from './restApi';

import styles from './EventForm.module.scss';

const EventForm = ({ timezones }) => {
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [startDay, setStartDay] = useState(null);
  const [endDay, setEndDay] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [image, setImage] = useState(null);
  const [imageFileName, setImageFileName] = useState(null);
  const [timezoneName, setTimezoneName] = useState('Eastern Time (US & Canada)');
  const [venueType, setVenueType] = useState('online');
  const [errorMessage, setErrorMessage] = useState(null);

  const base64Image = (image) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result
        .replace("data", "")
        .replace(/^.+,/, "");

      setImage(base64String);
      setImageFileName(image.name);
    };

    reader.readAsDataURL(image);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    createEvent({
      onResponse: (data) => {
        if (data.feedbackVariant === 'success') {
          window.location.assign("/events");
        } else {
          setErrorMessage(data.message);
        }
      },
      onError: ({ feedbackMessage, feedbackVariant }) => setErrorMessage(feedbackMessage),
      body: {
        authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content"),
        title: title,
        description: description,
        startDay: startDay,
        endDay: endDay,
        startTime: startTime,
        endTime: endTime,
        image: image,
        image_file_name: imageFileName,
        timezoneName: timezoneName,
        venue_type: venueType,
      },
    });
  };

  return (
    <React.Fragment>
      { errorMessage && <h3 className="error"> { errorMessage } </h3> }

      <form onSubmit={onSubmit} id={styles.loginForm}>
        <label className={styles.inputLabel}>Image</label>
        <input
          className={styles.input}
          type="file"
          accept="image/png,image/gif,image/jpeg"
          onChange={(e) => { base64Image(e.target.files[0]) }}
        />
        <label className={styles.inputLabel}>Title</label>
        <input
          className={styles.input}
          type="text"
          placeholder="Type the event title..."
          onChange={(e) => { setTitle(e.target.value) }}
        />
        <label className={styles.inputLabel}>Description</label>
        <textarea
          className={styles.textarea}
          placeholder="Type event description..."
          onChange={(e) => { setDescription(e.target.value) }}
        />
        <label className={styles.inputLabel}>Type Of Event</label>
        <select className={styles.input} onChange={(e) => { setVenueType(e.target.value) }}>
          <option value="online" selected>Online</option>
          <option value="in_person">In Person</option>
          <option value="hybrid">Online and In Person</option>
        </select>
        <label className={styles.inputLabel}>Start Date</label>
        <input
          className={styles.input}
          type="date"
          onChange={(e) => { setStartDay(e.target.value) }}
        />
        <label className={styles.inputLabel}>End Date</label>
        <input
          className={styles.input}
          type="date"
          onChange={(e) => { setEndDay(e.target.value) }}
        />
        <label className={styles.inputLabel}>Start Time</label>
        <input
          className={styles.input}
          type="time"
          onChange={(e) => { setStartTime(e.target.value) }}
        />
        <label className={styles.inputLabel}>End Time</label>
        <input
          className={styles.input}
          type="time"
          onChange={(e) => { setEndTime(e.target.value) }}
        />
        <label className={styles.inputLabel}>Timezone</label>
        <select className={styles.input} onChange={(e) => { setTimezoneName(e.target.value) }}>
          {timezones.map((timezone) => {
            return timezone.name == "Eastern Time (US & Canada)" ? <option value={timezone.name} selected>{timezone.display_name}</option> : <option value={timezone.name}>{timezone.display_name}</option>;
          })}
        </select>

        <input type="submit" name="commit" value="Create" className={styles.createPostBtn} data-disable-with="Create" />
      </form>
    </React.Fragment>
  );
};

EventForm.propTypes = {
  timezones: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      display_name: PropTypes.string.isRequired,
    }),
  ),
};

export default EventForm;
