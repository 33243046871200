import { HTTPRequestMethods } from "../../Shared/httpRequestMethods";

export const getRestrictedActions = ({ onResponse, onError }) => {
  fetch(
    "/api/restricted_actions",
    {
      method: HTTPRequestMethods.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const getRestrictedAction = ({ restrictedAction, onResponse, onError }) => {
  fetch(
    `/api/restricted_actions/${restrictedAction.id}`,
    {
      method: HTTPRequestMethods.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const addRoleToRestrictedAction = ({ restrictedAction, onResponse, onError, body }) => {
  fetch(
    `/api/restricted_actions/${restrictedAction.id}/add_role`,
    {
      method: HTTPRequestMethods.PUT,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const removeRoleFromRestrictedAction = ({ restrictedAction, onResponse, onError, body }) => {
  fetch(
    `/api/restricted_actions/${restrictedAction.id}/remove_role`,
    {
      method: HTTPRequestMethods.DELETE,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const addUserToRestrictedAction = ({ restrictedAction, onResponse, onError, body }) => {
  fetch(
    `/api/restricted_actions/${restrictedAction.id}/add_user`,
    {
      method: HTTPRequestMethods.PUT,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const removeUserFromRestrictedAction = ({ restrictedAction, onResponse, onError, body }) => {
  fetch(
    `/api/restricted_actions/${restrictedAction.id}/remove_user`,
    {
      method: HTTPRequestMethods.DELETE,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const createRestrictedAction = ({ onResponse, onError, body }) => {
  fetch(
    '/api/restricted_actions',
    {
      method: HTTPRequestMethods.POST,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};

export const updateRestrictedAction = ({ restrictedAction, onResponse, onError, body }) => {
  fetch(
    `/api/restricted_actions/${restrictedAction.id}`,
    {
      method: HTTPRequestMethods.PATCH,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
  .then(response => {
    return response.json().then((data) => {
      onResponse(data);
    });
  })
  .catch((error) => {
    onError({
      feedbackMessage: error.message,
      feedbackVariant: "error",
    });
  });
};
