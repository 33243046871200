import React from "react";

import { RoleFormProvider } from "./RoleFormContext";

import RoleFormFeedback from "./RoleFormFeedback";
import RoleForm from "./RoleForm";

import {
  createRole,
} from './restApi';

import styles from './RoleForm.module.scss';

const New = () => {
  const goToRolesIndex = () => {
    location.assign(`/roles/`);
  };

  return (
    <RoleFormProvider>
      <RoleFormFeedback />
      <section>
        <h2 className={styles.sectionTitle}>
          Create Restricted Action
        </h2>
        <RoleForm onSubmit={createRole}/>
        <button
          className={styles.cancelBtn}
          onClick={() => goToRolesIndex()}
        >
          Cancel
        </button>
      </section>
    </RoleFormProvider>
  );
}

export default New;
